// ���������� ��������������
const _isMobile = (thisWidth) => {
    let laptopXs = 769;

    if (thisWidth && thisWidth !== '') {
        laptopXs = thisWidth;
    }

    let _width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    let _isMobileAttr = false;
    if (_width <= laptopXs) {
        _isMobileAttr = true;
    }

    return _isMobileAttr;
}

function updateValues(inputsList, valueBlock, parentValueBlock) {
    if (inputsList.length <= 0) {
        return false
    }

    let input
    let typeBlock
    let hasValue = false

    for (let i = 0; i < inputsList.length; ++i) {
        input = inputsList[i]

        if ($(input).val() !== '') {
            hasValue = true
        }

        typeBlock = valueBlock.find('[data-type="' + $(input).attr('name') + '"]').eq(0)
        if (typeBlock.length <= 0) {
            continue
        }

        typeBlock.empty().html($(input).val())
    }

    if (!hasValue) {
        parentValueBlock.removeClass('show')
        return false;
    }

    parentValueBlock.addClass('show')
    return true
}

function setValueToTarget(target, value) {
    if ($(target).is("input")) {
        $(target).val(value)
        $(target).trigger('change')
    } else {
        $(target).empty().html(value)
    }
}

function updateSlideHeight() {
    if (_isMobile()) {
        $('.slick-list').height('auto');
    }
}

function sliderSlickSteps(slider) {
    slider.slick({
        slidesToShow: 1,
        swipe: false,
        dots: true,
        arrows: false,
        fade: true,
        cssEase: 'linear',
        infinite: false,
        adaptiveHeight: true,
    });

    return slider;
}

function sliderSlickMain(slider) {
    slider.slick({
        slidesToShow: 1,
        swipe: true,
        dots: false,
        arrows: true,
        fade: true,
        cssEase: 'linear',
        infinite: false,
        adaptiveHeight: true,
    });

    return slider;
}


function initSlider() {
    let slider = $('.flow-slider--wrap');
    if (slider.length > 0) {
        let dataSlider = slider.attr('data-slider')

        if (
            _isMobile() ||
            (_isMobile(991.98) && (dataSlider === 'main'))
        ) {
            if (slider.hasClass('slick-initialized')) {
                return false
            }

            //if (dataSlider === 'main-second') return false

            switch (dataSlider) {
                case 'main':
                    slider = sliderSlickMain(slider)
                    break;
                case 'main-second':
                    slider = sliderSlickMain(slider)
                    break;
                default:
                    slider = sliderSlickSteps(slider);
            }

            slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
                updateSlideHeight()
            });

            return true;
        }

        if (slider.hasClass('slick-initialized')) {
            slider.slick('unslick');
        }

        return true
    }

    return false
}

function fixMenu() {
    v1();
    v3();

    function v1() {
        if (!$(".flow-header").length) {
            return true;
        }
        let scroll = $(document).scrollTop();
        let topDist = $(".flow-header").position();
        if (scroll > topDist.top) {
            $('body').addClass('flow-header-fixed');
        } else {
            $('body').removeClass('flow-header-fixed');
        }
    }

    function v3(){
        if (!$("#header-v3").length) {
            return true;
        }

        if(!window.headerDesktopMenuLinePosition) {
            window.headerDesktopMenuLinePosition = $(".js-menu-line-to-fixed").position().top;
        }

        let scroll = $(document).scrollTop();
        if (scroll >= window.headerDesktopMenuLinePosition) {
            $('.js-header-v3').addClass('fixed');
        } else {
            $('.js-header-v3').removeClass('fixed');
        }
    }
}

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {}

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {});
}

function swiperRun(v, element) {
    let translateDeg

    $(v[0]).animate(v[1], {
        duration: 500,
        step: function(val) {
            translateDeg = val / 10;
            element.css("transform", "translateX("+ val +"px) rotate("+ translateDeg +"deg)");
        },
        done: function(){
            $(".swiper--card-cont").css('height', element.prev().outerHeight())
            element.addClass("hide").removeClass('visible');
            setTimeout(function() {
                element.attr("style", "")
            }, 200);

        }
    })
}

// ������� ���������� � wishlist
function initWishList() {
    $(document).on('click', '.js-wishlist-add', function(e) {
        e.preventDefault();
        let el = $(this);
        if (el.data('wishlist')) {
            wishlist.remove(el.data('products_id'), function() {
                el.data('wishlist', 0);
                el.removeClass('active');
            });
        } else {
            wishlist.add(el.data('products_id'), function() {
                el.data('wishlist', 1);
                el.addClass('active');
            });
        }
    });
}

function swiper() {
    var animating = false;
    var cardsCounter = 0;
    var numOfCards = 6;
    var decisionVal = 80;
    var pullDeltaX = 0;
    var deg = 0;
    var $card;

    function pullChange() {
        animating = true;
        deg = pullDeltaX / 10;
        $card.css("transform", "translateX("+ pullDeltaX +"px) rotate("+ deg +"deg)");
    }

    function release() {
        if (pullDeltaX >= decisionVal) {
            $card.addClass("to-right");
        } else if (pullDeltaX <= -decisionVal) {
            $card.addClass("to-left");
        }

        if (Math.abs(pullDeltaX) >= decisionVal) {
            $card.addClass("inactive");
            $card.removeClass('visible').addClass("hide");

            $card.prev().removeClass('hide').addClass("visible");

            setTimeout(function() {
                $card.addClass("below").removeClass("inactive to-left to-right");
                cardsCounter++;
                if (cardsCounter === numOfCards) {
                    cardsCounter = 0;
                    $(".swiper--card").removeClass("below");
                }
            }, 300);

            $(".swiper--card-cont").css('height', $card.prev().outerHeight())
        }

        if (Math.abs(pullDeltaX) < decisionVal) {
            $card.addClass("reset");
        }

        setTimeout(function() {
            $card.attr("style", "").removeClass("reset")
                .find(".swiper--card--choice").attr("style", "");

            pullDeltaX = 0;
            animating = false;
        }, 300);
    };

    $(document).on("mousedown touchstart", ".swiper--card:not(.inactive)", function(e) {
        if (animating) return;

        $card = $(this);
        var startX =  e.pageX || e.originalEvent.touches[0].pageX;

        $card.prev().removeClass('hide').addClass("visible");

        $(document).on("mousemove touchmove", function(e) {
            var x = e.pageX || e.originalEvent.touches[0].pageX;
            pullDeltaX = (x - startX);
            if (!pullDeltaX) return;
            pullChange();
        });

        $(document).on("mouseup touchend", function() {
            $(document).off("mousemove touchmove mouseup touchend");
            if (!pullDeltaX) return; // prevents from rapid click events
            release();
        });
    });

    $(document).on('click', ".swiper-control--left", function() {
        let cardVisible = $('.swiper--card.visible:not(.inactive)')

        cardVisible.prev().removeClass('hide').addClass("visible");

        if (cardVisible.length > 0) {
            swiperRun([{y:0}, {y:-500}], cardVisible)
        }
    })

    $(document).on('click', ".swiper-control--right", function() {
        let cardVisible = $('.swiper--card.visible:not(.inactive)')

        cardVisible.prev().removeClass('hide').addClass("visible");

        if (cardVisible.length > 0) {
            swiperRun([{y:0}, {y:500}], cardVisible)
        }
    })
}
fixMenu();
$(window).on('scroll', function(){
    fixMenu();
});

$(window).on('resize', function(){
    initSlider()
});

function initForm(wrapper) {
    $('.phone-11-dig', wrapper).phoneMask();
    //  bootstrap select

    $('.default-ajax-form', wrapper).ajaxForm(window.default_ajax_form_options);

    $('.default-validate-form', wrapper).each(function() {
        if($(this).attr('id')=='certificate-form'){
            defaultValidateSetup.description.commonDescription.numeric = '<div class="error">������� ������ �����</div>';
        }
        $(this).validate({
            onChange : $(this).data('validateOnChange') != undefined
        });
    });
}

(function() {
    initSlider();
    initForm($(document));

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {customClass: 'tooltip-custom', delay: {"show":100, "hide":100}}));

    if ($('.flow-main-testing--swiper').length > 0) {
        swiper();
    }

    if ($('.flow-lk--items').length > 0) {
        $('.flow-lk--items .flow-lk-result--body, .flow-lk--items .flow-lk-recomend--body').each(function( index ) {
            if ($(this).innerHeight() > 400) {
                $(this).parent().find('.flow-lk-result--more').eq(0).show();
            }
        })
    }

    if ($('#flow-girl').length > 0) {
        var elementGirl = document.getElementById('flow-girl');
        lottie.loadAnimation({
            container: elementGirl, // the dom element that will contain the animation
            renderer: 'canvas',
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
            },
            path: 'templates/flow/public/animation/dance_girl.json' // the path to the animation json
        });
    }

    if ($('#flow-preload').length > 0) {
        var element = document.getElementById('flow-preload');
        lottie.loadAnimation({
            container: element, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
            },
            path: '/templates/flow/public/animation/preload.json' // the path to the animation json
        });
    }


    $(document).on('click', ".switch-button .names .name--item", function() {
        var alreadyChecked = $(this).hasClass('active');

        if(alreadyChecked){
            return true
        }

        $('.switch-button .names .name--item').toggleClass('active');
        $('#switch-types').toggleClass('active');

        var targetBlock = $(this).attr('data-target');

        if (typeof targetBlock == 'undefined' || targetBlock === false) {
            return false;
        }

        $('.flow-main-filter--wrap').removeClass('show');

        $(targetBlock).addClass('show')
    })

    $(document).on('click', '[data-action="show"]', function (e) {
        let items = $(this).closest('.flow-lk--items');
        items.addClass('show')
        items.find('.flow-lk-result--more').hide()
    })

    $(document).on('click', '[data-action="copy"]', function (e) {
        let target = $(this).attr('data-target');
        let element = $(target);

        if (element && element.length > 0) {
            copyTextToClipboard(element.text());
        }

        return false;
    })

    $(document).on("click", ".flow-cart-step-pay--button a", function (t) {
        $('#paySuccessModal').modal('toggle')
    })

    $(document).on("click", ".flow-cart--title .icon--back", function (t) {
        if (_isMobile()) {
            $('.flow-cart--steps').slick('slickPrev');
        }
    })

    $(document).on("change", ".upload-input", function (t) {
        let inputId = $(this).attr('id')
        let inputFiles = this.files;
        if(inputFiles === undefined || inputFiles.length === 0) return;

        let inputFile = inputFiles[0];

        let reader = new FileReader();
        reader.onload = function(event) {
            let dataTarget = $('[data-target="' + inputId + '"]');
            if (dataTarget.length > 0) {
                dataTarget.css('background-image', 'url(' + event.target.result + ')');
            }
        };

        reader.onerror = function(event) {};

        reader.readAsDataURL(inputFile);
    })

    $(document).on("click", ".flow-cart-step--next a", function (t) {
        let wrap = $(this).closest('.flow-cart-step--one')
        let toShowBlock = wrap.attr('data-show')

        if (_isMobile()) {
            if (toShowBlock) {
                $(toShowBlock).show();
            }

            $('.flow-cart--steps').slick('slickNext')
            setTimeout(() => updateSlideHeight(), 250);

            return false
        }

        if (toShowBlock) {
            $(toShowBlock).show(1000);
        }

        return false
    })

    $(document).on("change", ".flow-cart-steps--how-deliver input", function (t) {
        let wrap = $(this).closest('.flow-cart-step--one')
        if (!wrap) {
            return false
        }

        let valueBlock = wrap.find('.flow-cart-step-one--values').eq(0)
        if (!valueBlock) {
            return false
        }

        $('[data-show="point"]').hide(200)
        $('[data-show="courier"]').hide(200);
        valueBlock.removeClass('show');

        if ($(this).val() === 'point') {
            $('[data-show="point"]').show(200)
            return true
        }

        if ($(this).val() === 'courier') {
            valueBlock.removeClass('show')
            $('.flow-cart-step-how-deliver--button').hide(200)
            $('[data-show="courier"]').show(200);
        }

        $('.flow-cart-steps--whom-delivery').show(1000);

        updateSlideHeight();
    })

    $(document).on("click", ".point-select--filter-types span:not(.deactive)", function (t) {
        $(this).toggleClass('active');
    })

    $(document).on("click", ".to-show", function (t) {
        let body = $(this).attr('data-body')
        let target = $(this).attr('data-target')
        let button = $(this).attr('data-button')

        $(body).hide(200)
        $(target).show(200)
        $(button).removeClass('active')

        $(this).addClass('active')
    })

    $(document).on("click", ".icon--filter-close", function (t) {
        let parentNode = $(this).parent()
        let parentId = parentNode.attr('aria-labelledby')
        $('#' + parentId).dropdown("toggle");
    })

    $(document).on("change", ".flow-cart-steps--town input, .flow-cart-steps--where-deliver input, .flow-cart-steps--where-deliver textarea, .flow-cart-steps--when-deliver input", function (t) {
        let wrap = $(this).closest('.flow-cart-step--one')
        if (!wrap) {
            return false
        }

        let valueBlock = wrap.find('.flow-cart-step-one--values').eq(0)
        if (!valueBlock) {
            return false
        }

        let toShowBlock = wrap.attr('data-show')

        let inputs = wrap.find('input, textarea');
        let hasValues =  updateValues(inputs, valueBlock, valueBlock)

        if (hasValues && toShowBlock) {
            $(toShowBlock).show(1000);
            updateSlideHeight()
        }

        return true
    })

    $(document).on("change", ".flow-cart-steps--whom-delivery input[name='name'], .flow-cart-steps--whom-delivery input[name='surname']", function (t) {
        let wrap = $(this).closest('.flow-cart-step--one')
        if (!wrap) {
            return false
        }

        let valueBlock = wrap.find('.flow-cart-step-one--values').eq(0)
        if (!valueBlock) {
            return false
        }

        let whomBlock = wrap.find('.flow-cart-step-value--whom-delivery').eq(0)
        if (!whomBlock) {
            return false
        }

        let inputName = wrap.find('input[name="name"]').eq(0)
        if (!inputName) {
            return false
        }

        let inputSurname = wrap.find('input[name="surname"]').eq(0)
        if (!inputSurname) {
            return false
        }

        if (inputName.val() === '' && inputSurname.val() === '') {
            valueBlock.removeClass('show')
            return true
        }

        let toShowBlock = wrap.attr('data-show')

        valueBlock.find('[data-type="name"]').empty().html(inputName.val())
        valueBlock.find('[data-type="surname"]').empty().html(inputSurname.val())

        valueBlock.addClass('show')

        if (toShowBlock) {
            $(toShowBlock).show(1000);
            updateSlideHeight();
        }

        return true
    })

    $(document).on("click", "[data-action='badges'] .badge", function (t) {
        let parent = $(this).parent()
        let value = $(this).attr('data-value')
        let target = parent.attr('data-target')
        let type = parent.attr('data-type')

        if (!value) {
            return false
        }

        if (!target) {
            return false
        }

        if (!type) {
            setValueToTarget(target, value)
        } else {
            let targetSecond = parent.attr('data-target-second')
            let valueArr = value.split(' ');

            if (valueArr[0]) {
                setValueToTarget(target, valueArr[0])
            }

            if (valueArr[1] && targetSecond) {
                setValueToTarget(targetSecond, valueArr[1])
            }
        }
    });

    $(document).on("click", ".flow-catalog-item-buy--grinding a.dropdown-item, .base-select-dropdown a.dropdown-item, .flow-catalog-filter-form--brewing a.dropdown-item, .flow-item-buy-delivery--select a.dropdown-item", function (t) {
        let value = $(this).attr('data-value');
        let text = $(this).text()
        let parent = $(this).closest('.dropdown');
        let btn = parent.find('.dropdown-toggle').eq(0);

        var input = btn.find('input')

        btn.find('span').empty().html(text)
        input.val(value);
        if(input.hasClass('js-filter-need-checked')){
            if(value){
                input.prop('checked',true);
            }else{
                input.prop('checked',false);
            }
        }

        parent.find('.dropdown-menu').eq(0).removeClass('show')

        parent.find('.dropdown-menu li.selected').removeClass('selected')

        $(this).parent().addClass('selected')

        return false
    });
})();
// ���������� �������������� - ���������

var customer = window.customer || new Customer();
var product = new Product({});
var cookieObj = new Cookie();
var shoppingCart = new ShoppingCart();
var wishlist = new WishList({
    setTotal: function(count) {
        $('.js-wishlist-count').text(count).attr('data-count',count);
    }
});

function sendBasketGaEvent(){
    switch ( window.PROSKTR.content ) {
        case 'index_default':
            if ( typeof ga !== "undefined"  ) ga('send', 'pageview', '/mainpage/add_to_cart');
            if ( window['yaCounter' + window.PROSKTR.yaCounterId] ) {
                window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('/mainpage/add_to_cart');
            }
            break;
        case 'index_products':
            if ( typeof ga !== "undefined"  ) ga('send', 'pageview', '/cat/add_to_cart');
            if ( window['yaCounter' + window.PROSKTR.yaCounterId] ) {
                window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('/cat/add_to_cart');
            }
            break;
        case 'product_info':
            if ( typeof ga !== "undefined"  ) ga('send', 'pageview', '/product/add_to_cart');
            if ( window['yaCounter' + window.PROSKTR.yaCounterId] ) {
                window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('/product/add_to_cart');
            }
            break;
        case 'wishlist':
            if ( typeof ga !== "undefined"  ) ga('send', 'pageview', '/wishlist/add_to_cart');
            if ( window['yaCounter' + window.PROSKTR.yaCounterId] ) {
                window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('/wishlist/add_to_cart');
            }
            break;
    }
}
// index0: mill
// index1: weight
// index2: millType
function getSizeDisabledWeights(bundles, millIndex, millTypeIndex) {
    var disabledIndexes = [];
    if (millIndex == 0) {
        millTypeIndex = 0;
    }
    for (var index1 in bundles[millIndex]) {
        if (bundles[millIndex][index1][millTypeIndex] != undefined && bundles[millIndex][index1][millTypeIndex].qty == 0) {
            disabledIndexes.push(index1);
        }
    }
    return disabledIndexes;
}

function getSizeDisabledMillAndMillTypes(bundles, weightIndex) {
    var disabledIndexes = [];
    for (var index0 in bundles) {
        for (var index2 in bundles[index0][weightIndex]) {
            if (bundles[index0][weightIndex][index2].qty == 0) {
                disabledIndexes.push([index0, index2]);
            }
        }
    }
    return disabledIndexes;
}

$(function(){
    initMainPage();
    initReviewsPage();
    initBlogPage();
    initPopup();
    initLogin();
    initNewUserRegistration();
    initCatalog();
    initProductInfo();
    initProductCard();
    initFooterSubscribe();
    initMenuHover();
    initAccount();
    initHeader();
    initWishList();

    function initHeader(){
        initHeaderBasket();
        initMobileMenu();

        function initMobileMenu(){
            $('.js-open-mobile-menu-v3').click(function (){
                $('.js-open-mobile-menu-v3-switcher').prop('checked',true);
            });
        }
        function initHeaderBasket(){
            shoppingCart.reloadSlider();
            $('.js-products-list-basket-modal').on('click', '.js-delete-product', deleteOneGoods);

            function deleteOneGoods(e){
                e.preventDefault();
                shoppingCart.deleteProduct({
                    products_id: $(this).attr('data-products_id')
                });
            }
        }
    }

    function initBlogPage(){
        var isBlogPage = $('.blog-page').length;

        if(!isBlogPage){
            return false;
        }
        initProductCardBasket();
        initBlogSlider();

        function initBlogSlider(){
            $('.js-blog-slider').slick({
                arrows:false,
                dots:true,
            });
        }
    }

    $('.default-validate-form').each(function() {
        $(this).validate({
            onChange : $(this).data('validateOnChange') != undefined
        });
    });

    function initPopup(){
        $('[data-popup-id]').click(function(){
            var popupId = $(this).attr('data-popup-id'),
                popup = $('#'+popupId+'.js-popup');

            if(popup){
                popup.addClass('active');
            }
        });

        $('.js-popup .js-close').click(function(){
            $(this).closest('.js-popup').removeClass('active');
        });
    }
    function initLogin(){
        $('.js-login').click(function(event){
            event.preventDefault();
            return false;
        });
        $('.js-phone-11-dig').phoneMask();
        $('#login-form').loginForm({
            onLoginFunc: function(msg) {
                window.location.href = msg.redirect;
            },
            inputRow: '.form-group',
        });
        initGetCode();
        initGetCodeAgain();
        initLoginButton();

        function initGetCode(){
            $('.js-get-pass').on('click', function(){
                if ( $(this).hasClass('disabled') ) return;
                $(this).addClass('disabled');
                let $form = $(this).closest('form');
                let $button = $(this);
                let phone = $form.find('input[name=phone]').val();
                let email_address = $form.find('input[name=email_address]').val();
                $('.js-form-error', $form).text('');
                $.ajax({
                    url: "/ajax_customer.php?action=checkAndSendPassCode&ajax=1",
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
                        email_address: email_address,
                    },
                    error: function(msg) {
                        $button.removeClass('disabled');
                        $('.js-form-error', $form).text(msg.err_mes);
                    },
                    success: function(msg) {
                        $button.removeClass('disabled');
                        if ( msg.result == 1 ) {
                            $button.addClass('d-none');
                            $('.js-code-holder, .js-login-btn, .js-reget-pass, .js-reget-pass-call', $form).removeClass('d-none');
                        }
                        $('.js-form-error', $form).text(msg.err_mes);
                    }
                });
            })
        }
        function initGetCodeAgain(){
            $('.js-reget-pass').on('click', function(){
                let $form = $(this).closest('form');
                let $button = $(this);
                let phone = $form.find('input[name=phone]').val();
                let email_address = $form.find('input[name=email_address]').val();
                $('.js-form-error', $form).text('');
                $.ajax({
                    url: "/ajax_customer.php?action=resendNamePassCode&ajax=1",
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
                        email_address: email_address,
                    },
                    error: function(msg) {
                        $('.js-form-error', $form).text(msg.err_mes);
                    },
                    success: function(msg) {
                        $('.js-form-error', $form).text(msg.err_mes);
                    }
                });
            });
            $('.js-reget-pass-call').on('click', function(){
                let $form = $(this).closest('form');
                let $button = $(this);
                let phone = $form.find('input[name=phone]').val();
                let email_address = $form.find('input[name=email_address]').val();
                $('.j-form-error', $form).html('');
                $.ajax({
                    url: "/ajax_customer.php?action=recallWithPassCode&ajax=1",
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        phone: phone.length > 0 ? '7' + phone.replace(/[^0-9]/g, '').substr(1) : '',
                        login: email_address,
                    },
                    error: function(msg) {
                        $('.js-form-error', $form).html(msg.err_mes);
                    },
                    success: function(msg) {
                        $button.addClass('d-none');
                        $('.js-form-error', $form).html(msg.err_mes);
                    }
                });
            });
        }
        function initLoginButton(){
            $('.js-login-btn').on('click', function(){
                if ( $(this).hasClass('disabled') ) return;
                $(this).addClass('disabled');
                let $form = $(this).closest('form');
                let $button = $(this);
                let phone = $form.find('input[name=phone]').val();
                let email_address = $form.find('input[name=email_address]').val();
                let passCode = $form.find('input[name=passCode]').val();
                $('.js-form-error', $form).text('');
                customer.loginByPassCode({
                    customerInfo: {
                        phone: phone,
                        passCode: passCode,
                        email_address: email_address
                    },
                    error: function(msg) {
                        $button.removeClass('disabled');
                        $('.js-form-error', $form).text(msg.err_mes);
                    },
                    success: function(msg) {
                        // window.open(
                        //     '/account.php',
                        //     '_blank' // <- This is what makes it open in a new window.
                        // );
                        window.location.href = '/account_password.php';
                    }
                });
            });
        }
    }

    function initNewUserRegistration(){
        var regForm = $('#popup-registration-form');
        regForm.on('submit', function(e) {
            e.preventDefault();
        });
        regForm.validate({
            onChange: true,
            valid: function(event, options) {
                var customer_info = {};
                var translate = {
                    'firstname': 'customers_firstname',
                    'email_address': 'customers_email_address',
                    'telephone': 'customers_telephone',
                    'password': 'customers_password',
                };
                $.each($(this).serializeArray(), function(k, v) {
                    customer_info[(translate[v.name] ? translate[v.name] : v.name)] = v.value;
                });
                customer.create({
                    customer_info: customer_info,
                    success: function() {
                        window.open(
                            '/account.php',
                            '_blank' // <- This is what makes it open in a new window.
                        );
                        location.reload();
                    },
                    error: function(msg) {
                        $('.error', '#popup-registration-form').find('.validation-description').html(msg.err_mes);
                    }
                });
            },
            invalid: function(a, b, c, d) {}
        });
    }

    function initMainPage(){
        var isMainPage = $('main #index').length > 0 || $('.flow-main-step--catalog').length > 0 || $('#index-default-v3').length > 0;

        if(!isMainPage){
            return false;
        }
        var listingEasy = new ListingEasy();

        initFilters();
        initProductCardBasket();
        initTopSlider();

        function initTopSlider(){
            $('.js-top-slider').slick({
                arrows:false,
                dots:true,
                autoplay: true,
            });
        }

        function initFilters(){
            changeFiltersInit();

            function changeFiltersInit() {
                removeFilterInit();
                changeTaste();

                function changeTaste() {
                    $('.js-filters-wrapper').on('change', '.js-change-filter', function(){
                        setActiveParentClass($(this));
                        filterChange();
                    });
                }


                function removeFilterInit(){
                    $('.js-selected-filters-wrapper').on('click', ".js-filter-delete", function (t) {
                        var filterName = $(this).attr('data-filter-id'),
                            filterValue = $(this).attr('data-value'),
                            filterElement = $('.j-filter-organizer[data-filter-id="'+filterName+'"][value="'+filterValue+'"]');

                        filterElement.prop('checked', false);
                        setActiveParentClass(filterElement);
                        $(this).remove();
                        filterChange();
                    });
                }
            }

            function filterChange(){
                var newGet = {},
                    path = '/cat/flow-coffee';

                setNewGet();
                for (var getParameter in newGet) {
                    newGet[getParameter] = newGet[getParameter].join(',');
                }

                listingEasy.getGoods({get: newGet, path: path}, function (data) {
                    render();
                    hideNoActiveFilters(data);
                    setUrlShowMore(path,newGet);
                    rewriteMobileSlider();

                    function render() {
                        var html = '';

                        html = $.render['listing_product_v2'].render(data, { currencies: window.PROSKTR.currencies });

                        if(window.innerWidth<992){
                            var countOfSlide = $('.js-product-list .slick-track .slick-slide').length;

                            for (var i=countOfSlide; i>0; i--){
                                $('.js-product-list').slick('slickRemove',i - 1);
                            }
                            $('.js-product-list').slick('slickAdd',html);
                            setTimeout(function(){
                                $('.js-product-list').slick('refresh');
                            },50);
                        }else{
                            $('.js-product-list').html(html).removeClass('loading-css');
                        }
                    }

                    function rewriteMobileSlider(){
                        $('.js-product-list').slick('unslick');
                        sliderSlickMain($('.js-product-list'));
                    }

                    function hideNoActiveFilters(data) {
                        $('.j-filter-organizer').each(function () {
                            var me = this,
                                filtrId = $(this).attr('data-filter-id'),
                                hasFilterData = data.filtrs && data.filtrs[filtrId] && data.filtrs[filtrId].values && data.filtrs[filtrId].values.noGroup,
                                filterValues = hasFilterData ? data.filtrs[filtrId].values.noGroup : [],
                                filterValue = $(this).val();

                            if (!hasFilterData) {
                                return true;
                            }
                            filterValues.forEach(function (valueData) {
                                if (valueData.value != filterValue) {
                                    return true;
                                }
                                if (valueData.enable && $(me).attr('disabled')) {
                                    $(me).removeAttr('disabled').parent().removeClass('disabled');
                                } else if (!valueData.enable && !$(me).attr('disabled')) {
                                    $(me).attr('disabled', 'disabled').parent().addClass('disabled');
                                }
                            });
                        });
                    }

                    function setUrlShowMore(path,get){
                        var search = '';

                        path = path ? path : location.pathname;
                        if(typeof get === "object"){
                            var searchArr = [];
                            for (var getParameter in get) {
                                if(getParameter === 'ajax'){
                                    continue;
                                }
                                searchArr.push(encodeURIComponent(getParameter)+ '=' + encodeURIComponent(get[getParameter]));
                            }
                            search = path;
                            if(searchArr.length){
                                search = search + '?'+ searchArr.join('&');
                            }
                        }
                        $('.js-show-more').attr('href',search);
                    }

                }, function () {
                    $('.j-desctop').removeClass('loading-css').addClass('not-load-css');
                });

                function setNewGet() {
                    $('.j-filter-organizer:checked').each(function () {
                        var filterId = $(this).attr('data-filter-id');

                        if (!filterId) {
                            return true;
                        }
                        if (typeof newGet[filterId] === "undefined") {
                            newGet[filterId] = [];
                        }
                        newGet[filterId].push($(this).val());
                    });
                }
            }

            function setActiveParentClass(filterElement){
                if(!(filterElement.hasClass('js-set-parent-active'))){
                    return false;
                }
                try {
                    if(filterElement.prop('checked')){
                        filterElement.parent().addClass('active');
                    }else{
                        filterElement.parent().removeClass('active');
                    }
                } catch (err) {
                    return false;
                }
            }
        }
    }

    function initProductCard(){
        var isProductCardPage = $('main.product-page').length > 0;

        if(!isProductCardPage){
            return false
        }

        var deliveryForm = $('#products_buttom_info_delivery');

        initBasket();
        initYouAlreadyBuy();
        deliveryLoad();
        initProductSlider();

        function initProductSlider(){
            $('.js-left-menu-switcher').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.js-main-slider',
                vertical:true,
                verticalSwiping:true,
                centerMode: true,
                focusOnSelect: true
            });
            $('.js-main-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.js-left-menu-switcher',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            fade: false,
                            dots: true,
                            autoplay:true,
                            autoplaySpeed: 5000,
                        }
                    },
                ]
            });
            $('.js-product-gallery-nav-popup').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.js-product-gallery-main-popup',
                vertical:true,
                verticalSwiping:true,
                centerMode: true,
                focusOnSelect: true
            });
            $('.js-product-gallery-main-popup').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: '.js-product-gallery-nav-popup',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            fade: false,
                            dots: true,
                            autoplay:true,
                            autoplaySpeed: 5000,
                        }
                    },
                ]
            });
            $('[data-popup-id="product-big-slider-popup"]').click(function(){
                $('.js-product-gallery-nav-popup').slick('refresh');
                $('.js-product-gallery-main-popup').slick('refresh');
            });
        }

        function initBasket(){
            var isCoffeePage = $('.js-add-product-coffee').length;
            if(isCoffeePage) {
                var coffeePriceVariants;

                coffeePriceVariantsInit();
                coffeeOptionsChangeInit();
                coffeeOptionsChange();
                buttonBuyInit();

                function coffeePriceVariantsInit() {
                    coffeePriceVariants = $('#coffee-bundles').data('bundles');
                }

                /**
                 * �������� �������� ������� input
                 * [name="product_weight"], [name="product_mill"], [name="product_mill_type"]
                 */
                function coffeeOptionsChangeInit() {
                    rememberOptions();
                    $('.j-change-size').change(function () {
                        var size = $(this).val();

                        $('[name="product_weight"]').val(size).attr('value', size).trigger('change');
                        coffeeOptionsChange();
                    });

                    checkIsOneSize();

                    function checkIsOneSize() {
                        $('.j-change-size').trigger('change');
                    }

                    $('.j-change-mill').change(function () {
                        var mill = $(this).val(),
                            isGroundCoffee = 1 == mill;

                        if (isGroundCoffee) {
                            $('.j-mill-type').removeClass('hide');
                            setMillType();
                        } else {
                            $('.j-mill-type').addClass('hide');
                            setMillType(true);
                        }
                        $('[name="product_mill"]').val(mill).attr('value', mill).trigger('change');
                        coffeeOptionsChange();
                    });

                    $('select.j-mill-type').change(function () {
                        var millType = $(this).val();

                        $('[name="product_mill_type"]').val(millType).attr('value', millType).trigger('change');
                        coffeeOptionsChange();
                    });

                    function setMillType(reset) {
                        var millType = reset ? 0 : $('select.j-mill-type').val();

                        $('[name="product_mill_type"]').val(millType).attr('value', millType).trigger('change');
                    }

                    /**
                     * ���������� ��������� ��������� ���� �������������
                     */
                    function rememberOptions() {
                        $('input[name="product_weight"]').change(function () {
                            cookieObj.setCookie('product_weight', $(this).val());
                        });
                        $('input[name="product_mill"]').change(function () {
                            cookieObj.setCookie('product_mill', $(this).val());
                        });
                        $('input[name="product_mill_type"]').change(function () {
                            cookieObj.setCookie('product_mill_type', $(this).val());
                        });
                        $('input[name="product_is_double"]').change(function () {
                            cookieObj.setCookie('product_is_double', $(this).val());
                        });
                    }
                }

                function buttonBuyInit() {
                    $('.js-add-product-coffee').click(function () {
                        var productForm = $('#add_product-form');

                        sendBasketGaEvent();
                        shoppingCart.addProducts({
                            product_info: productForm.serializeArray()
                        }, {
                            checkDublicate: false
                        });
                        switchButtonBuy(false);
                    });
                    changeCountAfterBuyInit();

                    function changeCountAfterBuyInit() {
                        buttonsInit();
                        $('.j-change-count-after-buy').change(function () {
                            var min = +($(this).attr('min')),
                                max = +($(this).attr('max')),
                                userValue = +($(this).val()),
                                newValue = userValue,
                                oldValue = +($(this).attr('data-previous-value')),
                                me = this,
                                productId = +$('#add_product-form').attr('data-products-id'),
                                productSizeId = getProductSubId();

                            if (!productSizeId) {
                                return false;
                            }
                            if (newValue < min) {
                                removeFromBasket();
                                newValue = min;
                            }

                            if (newValue > max) {
                                newValue = max;
                            }

                            setTitleValue(newValue);
                            $(this).attr('value', newValue).val(newValue);

                            if (userValue < min) {
                                updateOldValue(newValue);
                                return false;
                            }

                            if (oldValue !== newValue) {
                                changeCountInBasket();
                            }

                            updateOldValue(newValue);
                            animateChangeCount();

                            function getProductSubId() {
                                var weight = $('[name="product_weight"]').val(),
                                    mill = $('[name="product_mill"]').val(),
                                    millType = $('[name="product_mill_type"]').val(),
                                    currentCoffeeAttributes = coffeePriceVariants[mill][weight][millType];

                                return currentCoffeeAttributes.id ? currentCoffeeAttributes.id : null;
                            }

                            /**
                             * ���������� ������������ �������� ���������� � �������
                             */
                            function animateChangeCount() {
                                $('.go-to-basket-link').addClass('animate');
                                setTimeout(function () {
                                    $('.go-to-basket-link').removeClass('animate');
                                }, 3000);
                            }

                            return true;

                            function setTitleValue(newValue) {
                                $(me).parent().find('.j-chose-count-after-buy-label').text(newValue)
                            }

                            function updateOldValue(newValue) {
                                $(me).attr('data-previous-value', newValue);
                            }

                            function removeFromBasket() {
                                switchButtonBuy(true);
                                shoppingCart.deleteProduct({products_id: productId + '{1}' + productSizeId});
                            }

                            function changeCountInBasket() {
                                shoppingCart.changeProductCount(productId + '{1}' + productSizeId, newValue);
                            }
                        });

                        function buttonsInit() {
                            $('.j-basket-control-panel .j-chose-count .j-button-minus').click(doChange(-1));
                            $('.j-basket-control-panel .j-chose-count .j-button-plus').click(doChange(1));

                            function doChange(deltaInt) {
                                return function () {
                                    var changeWindow = $(this).closest('.j-chose-count').find('.j-change-count-after-buy');

                                    if (!changeWindow.length || !deltaInt) {
                                        return false;
                                    }

                                    var currentValue = +changeWindow.val(),
                                        newValue = +deltaInt + currentValue;

                                    changeWindow.val(newValue).attr('value', newValue);
                                    changeWindow.trigger('change');
                                }
                            }
                        }
                    }
                }

                /**
                 * ����������� ������� ��� ������ ������
                 */
                function switchButtonBuy(showButtonBuy) {
                    var buttonBuy = $('.j-basket-control-panel .js-add-product-coffee'),
                        buttonGoToBasket = $('.j-basket-control-panel .j-go-to-basket');

                    if (showButtonBuy) {
                        buttonBuy.addClass('active');
                        buttonGoToBasket.removeClass('active');
                    } else {
                        buttonBuy.removeClass('active');
                        buttonGoToBasket.addClass('active');
                    }
                }

                /**
                 * ��������� ������ � ��������� ���� ����
                 */
                function coffeeOptionsChange() {
                    var weight = $('[name="product_weight"]').val(),
                        mill = $('[name="product_mill"]').val(),
                        millType = $('[name="product_mill_type"]').val(),
                        currentCoffeeAttributes = coffeePriceVariants[mill][weight][millType],
                        isDouble = +$('[name="product_is_double"]').val(),
                        productId = $('#add_product-form').attr('data-products-id');

                    setProductForm();
                    setDeliveryForm();
                    drawProductPrice();
                    drawProductPhoto();
                    checkProductInBasket();

                    function setProductForm() {
                        var productForm = $('#add_product-form'),
                            productQuantity = isDouble ? 2 : 1;

                        $('.j-composite-product-id', productForm).val(currentCoffeeAttributes.id);
                        $('[name="cart_quantity"]').val(productQuantity).attr('value', productQuantity);
                    }

                    function setDeliveryForm() {
                        $('input[name="for_product"]', deliveryForm).val(productId + '{1}' + currentCoffeeAttributes.id);
                        deliveryLoad();
                    }

                    function drawProductPrice() {
                        var productPrice = currentCoffeeAttributes.price,
                            oldPrice = getOldPrice(currentCoffeeAttributes),
                            productCompositeId = $('.j-composite-product-id').val(),
                            discountWholesalePercent = currentCoffeeAttributes.benefit,
                            isWholesalePrices = $('.js-wholesale-prices').length;

                        if (isDouble) {
                            productPrice *= 2;
                            oldPrice *= 2;
                        }
                        if(isWholesalePrices){
                            var wholesalePricesArr =  $('.js-wholesale-prices').data('prices');

                            $('.js-wholesale-prices').html(wholesalePricesArr[currentCoffeeAttributes.id]);
                            $('.j-discount').attr('data-has-b2b', 1);
                        }
                        $('.js-current-price').text(productPrice);
                        if (oldPrice) {
                            $('.j-price-old-text').text(oldPrice);
                            $('.j-price-old').addClass('active');
                        } else {
                            $('.j-price-old').removeClass('active');
                        }
                        if (discountWholesalePercent) {
                            $('.j-discount').attr('data-has-wholesale', 1)
                                .find('.j-discount-wholesale')
                                .html(discountWholesalePercent);
                        } else {
                            $('.j-discount').attr('data-has-wholesale', 0);
                        }

                        couponLoad(productId, productCompositeId, drawCoupon);

                        function getOldPrice(data) {
                            var hasOldPrice = typeof data.beforeBenefit != undefined && data.beforeBenefit.length;

                            if (!hasOldPrice) {
                                return 0;
                            }
                            return +currentCoffeeAttributes.beforeBenefit.split(' ')[0];
                        }

                        function drawCoupon(coupon) {
                            var newProductPrice = coupon.price ? coupon.price : 0;

                            if (isDouble) {
                                newProductPrice *= 2;
                            }
                            if (!newProductPrice || productPrice == newProductPrice) {
                                $('.j-discount').attr('data-has-discount', 0).find('.j-discount-bonus').html('');
                                return true;
                            }
                            var percent = Math.round(((productPrice - newProductPrice) / productPrice) * 100),
                                currency = PROSKTR.currencies.rightMin,
                                saleTip = '������';

                            if (newProductPrice) {
                                $('.js-current-price').text(newProductPrice);
                                if (coupon.sources.indexOf('akcia') >= 0) {
                                    saleTip = '�� �����';
                                } else if (coupon.sources.indexOf('coupon') >= 0) {
                                    saleTip = '������';
                                }
                                var couponText = '� ������ -' + percent + '% ' + saleTip + ' (' + productPrice + ' ' + currency + ')';

                                $('.j-discount').attr('data-has-discount', 1).find('.j-discount-bonus').html(couponText);
                            }
                        }
                    }

                    function drawProductPhoto() {
                        var currentProductPhoto = $('.j-photos .j-img-wrapper[data-position="' + weight + '"]');

                        if (currentProductPhoto.length) {
                            $('.j-photos .j-img-wrapper').removeClass('active');
                            currentProductPhoto.addClass('active');
                        }
                    }

                    /**
                     * ��������� ������� ���������� ������ ������
                     * ��� ������ ������� � ������� � ������� ����������� � �������
                     */
                    function checkProductInBasket() {
                        shoppingCart.getProducts(function (data) {
                            var currentProductQuantityInBasket = +getCurrentProductQuantityInBasket(data);

                            if (!currentProductQuantityInBasket) {
                                switchButtonBuy(true);
                                $('.j-change-count-after-buy').val(1).attr('value', 1);
                                $('.j-chose-count-after-buy-label').html(1);
                            } else {
                                $('.j-change-count-after-buy').val(currentProductQuantityInBasket).attr('value', currentProductQuantityInBasket);
                                $('.j-chose-count-after-buy-label').html(currentProductQuantityInBasket);
                                switchButtonBuy(false);
                            }

                            function getCurrentProductQuantityInBasket(data) {
                                if (!Array.isArray(data.productsList) && !Array.isArray(data.products)) {
                                    return 0;
                                }
                                var productList = Array.isArray(data.productsList) ? data.productsList : data.products,
                                    currentProductCompositeId = productId + '{1}' + currentCoffeeAttributes.id;

                                for (let productInfo of productList) {
                                    if (productInfo.id === currentProductCompositeId) {
                                        return +productInfo.quantity;
                                    }
                                }
                                return 0;
                            }
                        })
                    }
                }
            }else{
                buttonBuyInit();
                changeQuantityInit();
                changeSizeInit();
                productOptionsChange();

                function changeSizeInit(){
                    $('.js-change-size').change(function(){
                        var new_size = $(this).val(),
                            max = $('.js-change-size option:selected').attr('data-max');

                        $('.j-product-size-id').attr('value',new_size).val(new_size);
                        $('.j-change-count-after-buy').attr('max',max);
                        if(!$('.js-add-product').hasClass('active')){
                            $('.j-basket-control-panel>*').toggleClass('active');
                        }
                    });
                }

                function buttonBuyInit() {
                    $('.js-add-product').click(function () {
                        var basketForm = $(this).hasClass('j-mobile') ? $('#add_product-form-mobile') : $('#add_product-form');

                        toggleBasketControlPanel();
                        sendBasketGaEvent();
                        shoppingCart.addProducts({
                            product_info: basketForm.serializeArray()
                        }, {
                            checkDublicate: false
                        });
                    });
                    changeCountAfterBuyInit();

                    /**
                     * ����������� ������� ��� ������ ������ �� �������������� ���-�� � �������
                     * � ��� ������ ���� ��� ��������� ������ ������������ ����������
                     */
                    function toggleBasketControlPanel(){
                        $('.j-basket-control-panel>*').toggleClass('active');
                    };

                    function changeCountAfterBuyInit() {
                        buttonsInit();
                        $('.j-change-count-after-buy').change(function () {
                            var min = +($(this).attr('min')),
                                max = +($(this).attr('max')),
                                userValue = +($(this).val()),
                                newValue = userValue,
                                oldValue = +($(this).attr('data-previous-value')),
                                me = this,
                                productId = +$('#add_product-form').attr('data-products-id'),
                                productSizeId = $('.j-composite-product-id').val();

                            if (newValue < min) {
                                removeFromBasket();
                                newValue = min;
                            }

                            if (newValue > max) {
                                newValue = max;
                            }

                            setTitleValue(newValue);
                            $(this).attr('value', newValue).val(newValue);

                            if (userValue < min) {
                                updateOldValue(newValue);
                                return false;
                            }

                            if (oldValue !== newValue) {
                                changeCountInBasket();
                            }

                            updateOldValue(newValue);
                            animateChangeCount();

                            /**
                             * ���������� ������������ �������� ���������� � �������
                             */
                            function animateChangeCount(){
                                $('.go-to-basket-link').addClass('animate');
                                setTimeout(function(){
                                    $('.go-to-basket-link').removeClass('animate');
                                },3000);
                            }
                            return true;

                            function setTitleValue(newValue) {
                                $(me).parent().find('.j-chose-count-after-buy-label').text(newValue)
                            }

                            function updateOldValue(newValue) {
                                $(me).attr('data-previous-value', newValue);
                            }

                            function removeFromBasket() {
                                toggleBasketControlPanel();
                                shoppingCart.deleteProduct({ products_id: productId + '{1}' + productSizeId });
                            }

                            function changeCountInBasket() {
                                shoppingCart.changeProductCount(productId + '{1}' + productSizeId, newValue);
                            }
                        });

                        function buttonsInit(){
                            $('.j-go-to-basket .j-chose-count .j-button-minus').click(doChange(-1));
                            $('.j-go-to-basket .j-chose-count .j-button-plus').click(doChange(1));

                            function doChange(deltaInt){
                                return function(){
                                    var changeWindow = $(this).closest('.j-chose-count').find('.j-change-count-after-buy');

                                    if(!changeWindow.length || !deltaInt){
                                        return false;
                                    }

                                    var currentValue = +changeWindow.val(),
                                        newValue = +deltaInt + currentValue;

                                    changeWindow.val(newValue).attr('value', newValue);
                                    changeWindow.trigger('change');
                                }
                            }
                        }
                    }
                }

                function changeQuantityInit() {
                    var hasChangeQuantityBlock = $('input[name="cart_quantity"]').length;

                    if (!hasChangeQuantityBlock) {
                        return false;
                    }

                    $('.j-quantity-variants').click(function () {
                        var newQuantity = $(this).attr('data-quantity'),
                            me = this;

                        setRepeatQuantity(newQuantity);
                        setNewQuantity()
                        switchButton();
                        productOptionsChange(); //!

                        function setRepeatQuantity(newQuantity) {
                            var repeatQuantity = $(me).attr('data-quantity');

                            $('[name="repeat_quantity"]').val(repeatQuantity).attr('value',repeatQuantity)
                        }

                        function setNewQuantity(){
                            var userChooseQuantity = +$(me).closest('.j-quantity').find('.j-quantity-number').val(),
                                repeatQuantity = +$(me).attr('data-quantity'),
                                resultQuantity = userChooseQuantity*repeatQuantity;

                            $('[name="cart_quantity"]').val(resultQuantity).attr('value',resultQuantity);
                        }

                        function switchButton() {
                            $('.j-quantity-variants', $(me).parent()).removeClass('active');
                            $(me).addClass('active');
                        }
                    });

                    $('.j-quantity-number').change(function(){
                        var repeatQuantity = $('[name="repeat_quantity"]').val(),
                            userChooseQuantity = $(this).val(),
                            min = +($(this).attr('min')),
                            max = +($(this).attr('max'));

                        if(userChooseQuantity < min){
                            removeFromBasket();
                            userChooseQuantity = min;
                        }else
                        if(userChooseQuantity > max){
                            userChooseQuantity = max;
                        }
                        $(this).val(userChooseQuantity);
                        var resultQuantity = userChooseQuantity*repeatQuantity,
                            title = $(this).parent().find('.j-quantity-number-label');

                        $('[name="cart_quantity"]').val(resultQuantity).attr('value',resultQuantity);
                        if(title.length){
                            title.text(userChooseQuantity);
                        }
                    });
                    $('.j-chose-quantity-button-minus').click(setQuantityNumber(-1));
                    $('.j-chose-quantity-button-plus').click(setQuantityNumber(1));

                    function setQuantityNumber(deltaInt){
                        return function(){
                            var inputPlace = $(this).parent().find('.j-quantity-number'),
                                value = inputPlace.length ? +inputPlace.val() : 0;

                            if(!value || !deltaInt){
                                return false;
                            }
                            inputPlace.val(+deltaInt + value);
                            inputPlace.trigger('change');
                        }
                    }
                }

                /**
                 * ���������� ������, ������ ����
                 */
                function productOptionsChange(){
                    var productId = $('[name="products_id"]').val(),
                        productQuantity = $('[name="cart_quantity"]').val(),
                        productPrice = +$('.js-current-price').attr('data-start-price'),
                        repeatQuantity = $('[name="repeat_quantity"]').val();

                    if ( repeatQuantity*productPrice ) {
                        $('.js-current-price').text(repeatQuantity*productPrice);
                    }
                    if(!setDiscount6Cup()){
                        couponLoad(productId,null,drawCoupon);
                    }

                    function drawCoupon(coupon){
                        var newProductPrice = coupon.price ? coupon.price : 0;

                        newProductPrice *= repeatQuantity;
                        productPrice *= repeatQuantity;

                        if(!newProductPrice || productPrice == newProductPrice){
                            $('.j-discount').attr('data-has-discount',0).find('.j-discount-bonus').html('');
                            return true;
                        }
                        var percent = Math.round(((productPrice - newProductPrice) / productPrice) * 100),
                            currency = PROSKTR.currencies.rightMin,
                            saleTip = '������';

                        if ( newProductPrice ) {
                            $('.js-current-price').text(newProductPrice);
                            if(coupon.sources.indexOf('akcia') >= 0){
                                saleTip = '�� �����';
                            } else if (coupon.sources.indexOf('coupon') >= 0) {
                                saleTip = '������';
                            }
                            var couponText = '� ������ -' + percent + '% ' + saleTip + ' (' + productPrice + ' ' + currency + ')';

                            $('.j-discount').attr('data-has-discount',1).find('.j-discount-bonus').html(couponText);
                        }

                    }

                    function setDiscount6Cup(){
                        var discountBlock = $('.j-quantity-variants.active[data-discount]'),
                            discount = discountBlock.length ? +discountBlock.attr('data-discount') : 0 ;

                        if(!discount){
                            var hasOtherDiscount = $('.j-price-old-text[data-old-price]').length > 0;

                            if(!hasOtherDiscount){
                                $('.j-price-old').removeClass('active');
                            }
                            return false;
                        }

                        $('.js-current-price').text(repeatQuantity*productPrice - discount);
                        $('.j-price-old-text').text(repeatQuantity*productPrice);
                        $('.j-price-old').addClass('active');
                        return true;
                    }
                }
            }

            function couponLoad(productId, productCompositeId, callback) {
                if (!+productId || typeof callback !== "function") {
                    return false;
                }
                var request = {
                    products_id: +productId,
                    action: 'recalc_price',
                };

                if (productCompositeId) {
                    var isProductSizeId = productCompositeId.indexOf('{1}') == -1;

                    request.fullAttrProduct = isProductSizeId ? '' + productId + '{1}' + productCompositeId : productCompositeId;
                }

                $.get('/ajax_fetch_coupons.php', request).done(callback);
            }
        }

        function initYouAlreadyBuy() {
            initProductCardBasket();
        }

        function deliveryLoad(){
            var url = deliveryForm.attr('action'),
                data = deliveryForm.serializeArray();

            $.post(url,data).done(function(data) {
                var deliveryWrapper = $('.js-product-delivery-wrapper');

                $('.product-delivery-list', deliveryWrapper).show();
                $('.product-delivery-choose-size', deliveryWrapper).hide().removeClass('loading');
                $('#product-delivery-res', deliveryWrapper).html(data.content[0]);
                $('#product-delivery-pre-title-res', deliveryWrapper).html(data.content[1]);
            }).fail(function() {});
        }

        $('#change-city-popup form').on('submit', function(e) {
            e.preventDefault();
            var city = $('.city-tab.active [name=city]', this).val();
            var zone_id = $('.city-tab.active [name=zone_id]', this).val();
            $('#change-city-popup .js-close').trigger('click');
            $('input[name=city]', deliveryForm).val(city);
            $('input[name=zone_id]', deliveryForm).val(zone_id);
            $('.js-delivery-city-text').text(city);
            deliveryLoad();
        });

    }

    /**
     * ��������� ������ ����
     * @returns {boolean}
     */
    function initCatalog(){
        var isCatalog = $('main.catalog').length > 0;

        if(!isCatalog){
            return false;
        }
        var listingEasy = new ListingEasy();

        initFilters();
        initSearch();
        initProductCardBasket();

        function initFilters(){
            changeFiltersInit();
            popupFiltersInit();
            initShowMore();
            initResetFiltersButton();

            function changeFiltersInit() {
                removeFilterInit();
                changeTaste();

                function changeTaste() {
                    $('.js-filters-wrapper').on('change', '.js-change-filter', function(){
                        setActiveParentClass($(this));
                        filterChange();
                    });
                }


                function removeFilterInit(){
                    $('.js-selected-filters-wrapper').on('click', ".js-filter-delete", function (t) {
                        var filterName = $(this).attr('data-filter-id'),
                            filterValue = $(this).attr('data-value'),
                            filterElement = $('.j-filter-organizer[data-filter-id="'+filterName+'"][value="'+filterValue+'"]');

                        filterElement.prop('checked', false);
                        setActiveParentClass(filterElement);
                        $(this).remove();
                        filterChange();
                    });
                }
            }

            function popupFiltersInit(){
                setActiveInit();
                clearFiltersInit();
                applyFiltersInit();

                function setActiveInit() {
                    $('.js-filter-popup').change(function(){
                        var dataFilterId = $(this).attr('data-filter-id'),
                            value = $(this).attr('value'),
                            checked = $(this).prop('checked');

                        $('[data-filter-id="'+dataFilterId+'"][value="'+value+'"]').each(function () {
                            $(this).prop('checked', checked);
                            setActiveParentClass($(this));
                        });
                        filterChange();
                    });
                }

                function clearFiltersInit(){
                    $('.js-filter-popup-clear').click(function (t) {
                        let parentNode = $(this).closest('.flow-catalog-filter-form--wrap');
                        if (!parentNode) {
                            return false
                        }

                        let result = $('.flow-catalog-filters-form--result');
                        if (!result || result.length === 0) {
                            return false
                        }

                        result.empty()

                        parentNode.find('.flow-catalog-filter-form--badgets .badge.active').removeClass('active');

                        let dropdownBrewing = parentNode.find('#dropdownBrewing')
                        if (dropdownBrewing) {
                            dropdownBrewing.parent().find('.dropdown-menu li:first-child a').click()
                        }

                        $('.j-filter-organizer:checked').each(function(){
                            $(this).prop('checked', false);
                            setActiveParentClass($(this));
                        });
                        filterChange();
                    })
                }

                function applyFiltersInit() {
                    $('.js-filter-popup-submin').click(function (t) {
                        let parentNode = $(this).closest('.flow-catalog-filter-form--wrap');
                        if (!parentNode) {
                            return false
                        }

                        let result = $('.flow-catalog-filters-form--result');
                        if (!result || result.length === 0) {
                            return false
                        }

                        result.empty()

                        let activeBadgets = parentNode.find('.flow-catalog-filter-form--badgets .badge.active');
                        if (activeBadgets && activeBadgets.length > 0) {
                            activeBadgets.each(function() {
                                var name=$(this).attr('data-value'),
                                    input = $('input',$(this)),
                                    filterId = input.attr('data-filter-id'),
                                    value = input.attr('value');

                                result.append('<div class="badge badge-rounded badge-filter active js-filter-delete"' +
                                    ' data-filter-id="'+filterId+'"' +
                                    ' data-value="'+value+'"' +
                                    '>'+name+'</div>'
                                );
                            });
                        }

                        let dropdownBrewing = parentNode.find('#dropdownBrewing')
                        if (dropdownBrewing) {
                            let selected = dropdownBrewing.parent().find('.dropdown-menu li.selected a')
                            if (selected.length > 0) {
                                if (selected.attr('data-value') !== '') {
                                    result.append(
                                        $('<div>', {
                                            "data-value": selected.attr('data-value'),
                                            "data-filter-id": 'recomendyemii_sposob_prigotovlen',
                                            "class": 'badge badge-rounded badge-filter active js-filter-delete',
                                        }).html(selected.text())
                                    );
                                }
                            }
                        }
                        $('#dropdownFilter').dropdown("toggle");
                        closePopup();
                        filterChange();
                    });
                }
                function closePopup(){
                    $('#filter-show-switcher').prop('checked',false);
                }
            }


            function filterChange(page,appendList){
                scroolUpMobile();
                var newGet = {page:[page||1]},
                    path = location.pathname.split('/').slice(0,3).join('/');

                setNewGet();
                for (var getParameter in newGet) {
                    newGet[getParameter] = newGet[getParameter].join(',');
                }

                listingEasy.getGoods({get: newGet, path: path}, function (data) {
                    render();
                    hideNoActiveFilters(data);
                    initShowMore(data.pagination);

                    function initShowMore(pagination){
                        if(pagination.isLast){
                            $('.js-show-more').addClass('disabled');
                        }else{
                            $('.js-show-more').removeClass('disabled');
                            $('.js-show-more').attr('data-page',+pagination.pageNumber+1);
                        }
                        if(page>1){
                            $('.js-show-more').attr('data-page', page + 1).removeClass('loading-css').removeClass('js-loading');
                        }
                    }

                    function render() {
                        var html = '';

                        html = $.render['listing_product_v2'].render(data, { currencies: window.PROSKTR.currencies });
                        if(appendList){
                            $('.js-product-list').append(html).removeClass('loading-css');
                        }else {
                            $('.js-product-list').html(html).removeClass('loading-css');
                        }
                    }

                    function hideNoActiveFilters(data) {
                        $('.j-filter-organizer').each(function () {
                            var me = this,
                                filtrId = $(this).attr('data-filter-id'),
                                hasFilterData = data.filtrs && data.filtrs[filtrId] && data.filtrs[filtrId].values && data.filtrs[filtrId].values.noGroup,
                                filterValues = hasFilterData ? data.filtrs[filtrId].values.noGroup : [],
                                filterValue = $(this).val();

                            if (!hasFilterData) {
                                return true;
                            }
                            filterValues.forEach(function (valueData) {
                                if (valueData.value != filterValue) {
                                    return true;
                                }
                                if (valueData.enable && $(me).attr('disabled')) {
                                    $(me).removeAttr('disabled').parent().removeClass('disabled');
                                } else if (!valueData.enable && !$(me).attr('disabled')) {
                                    $(me).attr('disabled', 'disabled').parent().addClass('disabled');
                                }
                            });
                        });
                    }

                }, function () {
                    if(page>1){
                        $('.js-show-more').removeClass('loading-css').removeClass('js-loading').addClass('not-load-css');
                    }else{
                        $('.j-desctop').removeClass('loading-css').addClass('not-load-css');
                    }
                });

                function setNewGet() {
                    $('.j-filter-organizer:checked').each(function () {
                        var filterId = $(this).attr('data-filter-id');

                        if (!filterId) {
                            return true;
                        }
                        if (typeof newGet[filterId] === "undefined") {
                            newGet[filterId] = [];
                        }
                        newGet[filterId].push($(this).val());
                    });
                }

                function scroolUpMobile(){
                    if(window.innerWidth < 992){
                        $("html, body").animate({ scrollTop: 0 }, "slow");
                    }
                }
            }

            function setActiveParentClass(filterElement){
                if(!(filterElement.hasClass('js-set-parent-active'))){
                    return false;
                }
                try {
                    if(filterElement.prop('checked')){
                        filterElement.parent().addClass('active');
                    }else{
                        filterElement.parent().removeClass('active');
                    }
                } catch (err) {
                    return false;
                }
            }

            function initShowMore(){
                $('.js-show-more').click(function () {
                    if($(this).hasClass('js-loading')){
                        return false;
                    }
                    var page = +($(this).attr('data-page'));

                    $(this).addClass('loading-css').addClass('js-loading').removeClass('not-load-css');
                    filterChange(page,1);
                });
            }

            function initResetFiltersButton() {
                $('.js-reset-filters').click(function () {
                    $('[name="filter-type-filter-coffee"]:checked').closest('label').trigger('click');
                });
            }
        }
        function initSearch(){
            $('#search-show-switcher').change(function(){
                if($(this).prop('checked')){
                    $('.js-search').focus();
                }
            })
        }
    }
    function initProductCardBasket() {
        $('.js-product-list').on('click', '.j-product-size li', function () {
            if ($(this).hasClass('active')) {
                return false;
            }
            $(this).parent().children().removeClass('active');
            $(this).addClass('active');

            //������ ������� - � ������� � �������� ���������������� �� ������ ������������� ���� �������
            var index = +$(this).index(),
                cardBasketBlock = $(this).closest('.j-product-footer'),
                allCardBaskets = $('.j-add-product',cardBasketBlock);

            $('.j-add-product',cardBasketBlock).removeClass('j-current').removeClass('active-css');
            $(allCardBaskets[index]).addClass('j-current').addClass('active-css');

            //������ ����
            $('.j-cost', $(this).closest('.j-product-footer')).html($(this).attr('data-price'));

        }).on('click', '.j-add-product.j-current:not(.j-active)', function () {
            if ($(this).hasClass('j-active')) {
                return false;
            }
            $('.basket-title', $(this)).hide();
            $(this).addClass('in-basket-css').addClass('j-active').children().addClass('active');
            var options = getproductCardOptions($(this).closest('.j-product-footer')),
                wholesaleBlock = $(this).closest('.j-product-container').find('.j-wholesale');

            if(wholesaleBlock.length){
                wholesaleBlock.fadeOut();

                var priceBlock = $(this).closest('.j-product-footer').find('.j-price-format');

                priceBlock.text(priceBlock.attr('data-base-price'))
            }
            addToBasket(options.productId, options.sizeId, options.quantity);

        }).on('click', '.j-button-minus', function () {
            var input = $('input[type="number"]',$(this).closest('.j-chose-count')),
                options = getproductCardOptions($(this).closest('.j-product-footer')),
                count = +(input.attr('value')) - 1,
                titleId = $(this).attr('data-title-id');

            $('.j-button-plus', $(this).closest('.j-chose-count')).removeClass('blocked');
            if(count > 0) {
                changeCountFromBasket(options.productId, count, options.sizeId);
            }else{
                var basketIco = $(this).closest('.j-add-product.j-current'),
                    wholesaleBlock = $(this).closest('.j-product-container').find('.j-wholesale');

                count = 1;
                if(wholesaleBlock.length){
                    wholesaleBlock.children().removeClass('active');
                    $(wholesaleBlock.children()[0]).addClass('active');
                    wholesaleBlock.fadeIn();
                }
                basketIco.removeClass('j-active').removeClass('in-basket-css');
                basketIco.children().removeClass('active');
                $('.basket-title', basketIco).show();
                removeFromBasket(options.productId, options.sizeId);
            }
            input.attr('value',count).val(count);
            changeTitle(titleId);

        }).on('click', '.j-button-plus', function () {
            var input = $('input[type="number"]',$(this).closest('.j-chose-count')),
                options = getproductCardOptions($(this).closest('.j-product-footer')),
                count = +(input.attr('value')) + 1,
                max = +input.attr('max'),
                titleId = $(this).attr('data-title-id');

            if(count === max){
                $(this).addClass('blocked');
            }
            if(count > max){
                $(this).addClass('blocked');
                count = max;
            }
            input.attr('value',count).val(count);
            changeCountFromBasket(options.productId, count, options.sizeId);
            changeTitle(titleId);

        }).on('change', '.j-count', function(){
            var count = +($(this).val()),
                options = getproductCardOptions($(this).closest('.j-product-footer')),
                max = +$(this).attr('max'),
                min = +$(this).attr('min'),
                titleId = $(this).attr('id');

            if(count > max){
                count = max;
            }
            if(count >= min){
                changeCountFromBasket(options.productId, count, options.sizeId);
            }else{
                var basketIco = $(this).closest('.j-add-product.j-current');

                count = min;
                basketIco.removeClass('j-active').removeClass('in-basket-css');
                $('.basket-title', basketIco).show();
                removeFromBasket(options.productId, options.sizeId);
            }
            $(this).attr('value',count).val(count);
            changeTitle(titleId);

        }).on('click', '.j-easy-chose-count',function(){
            if($(this).hasClass('active')){
                return true;
            }
            $(this).parent().children().removeClass('active');
            $(this).addClass('active');

            var priceBlock = $(this).closest('.product-container').find('.j-price-format'),
                productCountBlock = $(this).closest('.product-container').find('.j-product-footer .j-add-product.j-current .j-count'),
                count = +($(this).attr('data-count')),
                price = $(this).attr('data-price');

            priceBlock.text(price);
            productCountBlock.attr('value',count).val(count);
        }).on('change', '.j-product-size',function(){
            var selectedSize = $(this).find('option:selected'),
                productPrice = selectedSize.attr('data-price'),
                parent = $(this).closest('.j-product-footer'),
                priceEl = parent.find('.j-cost'),
                basketEl = parent.find('.j-add-product'),
                countEl = parent.find('.j-count'),
                couponPrice = $(selectedSize).attr('data-coupon_price'),
                isNeedWriteCouponPrice = typeof couponPrice !== "undefined" && couponPrice !== false;

            if(isNeedWriteCouponPrice) {
                writeOldPrice(selectedSize, parent,priceEl);
            }else{
                priceEl.html(productPrice);
            }
            basketEl.removeClass('in-basket-css');
            basketEl.removeClass('j-active');
            basketEl.children().removeClass('active');
            countEl.val(1).attr('value', 1);

            function writeOldPrice(selectedSize,parent,priceEl){
                var couponPrice = selectedSize.attr('data-coupon_price'),
                    alreadyCalculatedPrice = couponPrice>0,
                    oldPriceContainer = parent.find('.js-old-price')

                oldPriceContainer.html(selectedSize.attr('data-price'));
                if(alreadyCalculatedPrice){
                    priceEl.html(couponPrice);
                    if(productPrice === couponPrice){
                        oldPriceContainer.parent().addClass('none');
                    }else{
                        oldPriceContainer.parent().removeClass('none');
                    }
                    return true;
                }

                var products_id = selectedSize.attr('data-products_id'),
                    option_id = selectedSize.attr('data-option_id'),
                    data = {
                        action: "recalc_price",
                        fullAttrProduct: products_id+'{1}'+option_id,
                        products_id:products_id,
                    };
                $.get('/ajax_fetch_coupons.php',data).done(function(reply){
                    if ( reply !== null ) {
                        if ( reply.price > 0 && reply.start_price > 0 && reply.price <= reply.start_price ) {
                            priceEl.html(reply.price);
                            selectedSize.attr('data-coupon_price',reply.price);
                            if(reply.price === reply.start_price){
                                oldPriceContainer.parent().addClass('none');
                            }else{
                                oldPriceContainer.parent().removeClass('none');
                            }
                        }
                    }
                });
            }
        });

        function getproductCardOptions(productCard){
            var isSizes = $('.j-product-size', productCard).length,
                sizeId,
                quantity = $('.j-count',productCard).attr('value');

            if(isSizes){
                sizeId = $('.j-product-size', productCard).val();
                if(!sizeId) {
                    sizeId = $('.j-product-size>*', productCard).attr('data-id');
                }
            }else{
                sizeId = 5084;
            }

            return {
                productId : $('.j-add-product.j-current',productCard).attr('data-products_id'),
                sizeId : sizeId,
                quantity: quantity,
            }
        }

        function addToBasket(productId, optionId, quantity ) {
            quantity = quantity ? quantity : 1;

            var products = {
                product_info: [
                    { name: "cart_quantity", value: quantity },
                    { name: "products_id", value: productId},
                    { name: "param", value: 2 },
                    { name: "id[1]", value: optionId}
                ]
            };

            sendBasketGaEvent();
            shoppingCart.addProducts(products, { checkDublicate: false });
        }
        function changeCountFromBasket(productId, count, optionId) {
            shoppingCart.changeProductCount(productId+'{1}'+optionId, count);
            $('.header-basket-content').removeClass('add-goods');
        }
        function removeFromBasket(productId, optionId) {
            shoppingCart.deleteProduct({ products_id: productId +'{1}'+ optionId });
            $('.header-basket-content').removeClass('add-goods');
        }
        function changeTitle(titleId){
            var titleLabelNumber = $('.j-chose-quantity-block-label[data-title-id="'+titleId+'"]'),
                input = $('#'+titleId);

            if(!titleLabelNumber.length || !input.length){
                return false;
            }

            titleLabelNumber.text(input.val());
        }
    }
    function initFooterSubscribe(){
        $('.js-subscribe-form').submit(function(e){e.preventDefault();});

        $('.js-subscribe').click(function(){
            var alreadySubscribe = +$(this).attr('data-already-subscribe'),
                emailElement = $(this).parent().find('.js-email-address'),
                email = emailElement.val(),
                acceptTermsEl = $('.js-accept-terms'),
                acceptTerms = acceptTermsEl.prop('checked') ;

            if(email.length<5){
                emailElement.css({border:'solid 2px red'});
                setTimeout(function (){
                    emailElement.css({border:'solid 1px black'});
                },3000);
            }
            if(!acceptTerms){
                acceptTermsEl.css({border:'solid 2px red'});
                setTimeout(function (){
                    acceptTermsEl.css({border:'none'});
                },3000);
            }
            if(alreadySubscribe || email.length<5 || !acceptTerms){
                return false;
            }

            $('.js-subscribe').attr('data-already-subscribe', 1 )

            $.get('/sent_coupon_500_5day.php',{email:email,ajax:true})
                .done(function(ans){

                    if (typeof ans.res!=='undefined' && ans.res === '1') {
                        $('.js-subscribe-form').fadeOut(300);
                        setTimeout(function(){
                            $('.js-subscribe-success').fadeIn(300);
                        },300);

                    }
                    else if(typeof ans.err_mes!=='undefined') {
                        $('.js-subscribe-error').html(ans.err_mes).fadeIn(300);
                        $('.js-subscribe').attr('data-already-subscribe', 0 );
                    }
                });
        });
    }

    /**
     * ��� ��������� ������� ���� ���� - ������� ����
     */
    function initMenuHover(){
        $('#navbarContent').mouseleave(function(e){
            let y = e.clientY;

            if(y>500){
                $('[data-bs-target="#navbarContent"]').trigger('click');
            }
        });
    }
    function initAccount() {
        initPaymentPage();

        function initPaymentPage() {
            var isAccauntPage = $('main.account_payment').length > 0;

            if (!isAccauntPage) {
                return;
            }
            $('#account_payment [name=order_id]').change(function () {
                var form = $('#account_payment');
                var selected_option = $('[name=order_id] option:selected', form);
                var sum = selected_option.data('sum');
                var sumDisc = selected_option.data('sum_disc');
                var orders_id = $(this).val();
                $('.order_slider').removeClass('active');
                $('div[name=' + orders_id + ']', '.reviews-ordered').addClass('active');
                $('.prepay-disc-desc', form)[sum == sumDisc ? 'hide' : 'show']();
                $('[name=summa]', form).val('');
                $('#payment-ot-total', form).text(selected_option.data('ot_total_str'));
                $('#payment-prepay', form).text(selected_option.data('prepay'));
                $('#payment-to-prepay', form).text(selected_option.data('to_prepay'));
                if (selected_option.data('disc_value') > 0) {
                    $('#labelOnLinePay').text('(1������ ' + selected_option.data('disc_value') + '%  ��� ������ ������):');
                    $('.full-sum', form).text(sumDisc + ' P, � ������ -' + selected_option.data('disc_value') + '%');
                }
                if (selected_option.data('val_ship') == 1) {
                    if (selected_option.data('disc_value') > 0) {
                        $('#labelOnLinePay').text('(������ ' + selected_option.data('disc_value') + '% ��� ������ ������ � ���������� ��������):');
                    } else {
                        $('#labelOnLinePay').text('���������� �������� ��� ������ ������):');
                    }
                }
                if (selected_option.data('val_ship') == 1 && $('[name=payment]:checked').data('type') != 'OffLine') {
                    if (selected_option.data('disc_value') > 0) {
                        $('.full-sum', form).html('<strong style="font-size:14pt">' + sumDisc + ' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% � ���������� ��������');
                    } else {
                        $('.full-sum', form).html('� ���������� ���������');
                    }
                }
                if (selected_option.data('val_ship') != 1 && $('[name=payment]:checked').data('type') != 'OffLine') {
                    if (selected_option.data('disc_value') > 0) {
                        $('.full-sum', form).html('<strong style="font-size:14pt">' + sumDisc + ' P</strong><br> � ������ -' + selected_option.data('disc_value') + '%');
                    } else {
                        $('.full-sum', form).html('<strong style="font-size:14pt">' + sumDisc + ' P</strong>');
                    }
                }
                if ($('[name=payment]:checked').data('type') == 'OffLine') {
                    $('.full-sum', form).text(selected_option.data('to_prepay'));
                }
            }).trigger('change');
            $('[name=payment]').change(function () {
                var selected_option = $('[name=order_id] option:selected');
                var sumDisc = selected_option.data('sum_disc');
                if ($(this).data('type') == 'OnLine' && selected_option.data('val_ship') == 1) {
                    if (selected_option.data('disc_value') > 0) {
                        $('.full-sum').html('<strong style="font-size:14pt">' + sumDisc + ' P</strong><br> � ������ -' + selected_option.data('disc_value') + '% � ���������� ��������');
                    } else {
                        $('.full-sum').html('<br> � ������ ���������� ��������');
                    }
                }
                if ($(this).data('type') == 'OnLine' && selected_option.data('val_ship') != 1) {
                    if (selected_option.data('disc_value') > 0) {
                        $('.full-sum').html('<strong style="font-size:14pt">' + sumDisc + ' P</strong><br> � ������ -' + selected_option.data('disc_value') + '%');
                    } else {
                        $('.full-sum').html('<strong style="font-size:14pt">' + sumDisc + ' P</strong>');
                    }
                }
                if ($(this).data('type') == 'OffLine') {
                    $('.full-sum').html('<strong style="font-size:14pt">' + selected_option.data('to_prepay') + '</strong>');
                }

            });
        }
    }
    function initProductInfo(){
        if($('.js-reviews-content').length){
            initShowMore();
        }

        function initShowMore() {
            $('.j-reviews-show-more').click(function () {
                const reviewCountOnPage = 10;
                var page = +$(this).attr('data-page'),
                    me = this,
                    alreadyLoading = $(this).hasClass('j-loading');

                if (alreadyLoading) {
                    return false;
                }
                $(this).addClass('loading-css').addClass('j-loading').removeClass('not-load-css');

                product.getMoreReviews({
                    products_id: $(this).attr('data-products-id'),
                    limit: page * reviewCountOnPage,
                    offset: page > 2 ? page * reviewCountOnPage - reviewCountOnPage : 5,
                    type: 'product',
                }, function (data) {
                    $(me).attr('data-page', page + 1).removeClass('loading-css').removeClass('j-loading');
                    $('.js-reviews-content').append(data.data);
                    if (+data.reviewsCount <= page * reviewCountOnPage) {
                        $(me).addClass('none');
                    }
                }, function () {
                    $(me).removeClass('loading-css').removeClass('j-loading').addClass('not-load-css');
                });
            });
        }
    }
    function initReviewsPage() {
        var wrapper = $('.js-comment');
        if (wrapper.length == 0) {
            return;
        }
        var form = $('#reviews-list-form', wrapper);
        var startParams = form.serializeArray();
        $('.js-more-reviews', wrapper).click(function(e) {
            e.preventDefault();
            $(this).fadeOut();
            if (parseInt(form.attr('loading')) == 1) {
                return false;
            }
            form.attr('loading', 1);
            var page_el = form.find('input[name=page]');
            page_el.val(parseInt(page_el.val()) + 1);
            formChangeFunc(form, true, true);
        });
        form.change(function(e) {
            if (parseInt(form.attr('loading')) == 1) {
                return false;
            }
            form.attr('loading', 1);
            form.find('input[name=page]').val(1);
            formChangeFunc(form, false, true);
        });

        var formChangeFunc = function(form, appendFlag, historyFlag) {
            var formData = form.serializeArray();
            if (historyFlag && history.pushState) {
                var queryString = $.param(formData);
                history.pushState(formData, $("title").text(), form.attr('action') + '?' + queryString.replace('&ajax=3', ''));
            }
            $.ajax({
                url: form.attr('action'),
                data: formData,
                dataType: 'json',
                success: function(msg) {
                    if (appendFlag) {
                        $('.js-reviews-list', wrapper).append(msg.data.content);
                    } else {
                        $('.js-reviews-list', wrapper).html(msg.data.content);
                        // $(window).scrollTop(0);
                    }
                    form.attr('loading', 0);
                    // $('.select-info .count-model', wrapper).text(msg.pagination.itemCountText);
                    $('.js-reviews-navigation', wrapper).html(msg.pagination.html);
                    if (parseInt(msg.pagination.pageNumber) >= parseInt(msg.pagination.pageCount)) {
                        $('.js-more-reviews', wrapper).fadeOut();
                    } else {
                        $('.js-more-reviews', wrapper).fadeIn();
                    }

                },
            });
        }
        $('.js-reviews-navigation', wrapper).on('click', '.page-link', function(e) {
            e.preventDefault();
            if (parseInt(form.attr('loading')) == 1) {
                return false;
            }
            form.attr('loading', 1);
            var page_el = form.find('input[name=page]');
            page_el.val($(this).data('page'));
            formChangeFunc(form, false, true);
        });


        addEvent('popstate', window, function(e) {
            if (e.state === null) {
                params = startParams;
            } else {
                params = e.state;
            }
            $('#reviews-list-form').deserialize($.param(params), true);
            $('.radio-list input:checked').closest('label').addClass('active').siblings().removeClass('active');
            formChangeFunc($('#reviews-list-form'), false, false);
        });
    }
});
function initProductItem(){
}
function initDeliveryPage() {
	wrapper = $('.main.delivery');
	if( wrapper.length == 0 ) {
		return ;
	}
	$(wrapper).on('click', '.btn-see-map', function( e, delivery ) {
		e.preventDefault();
		var form = $(this).closest('form');
		$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
		var tmp_options = form.serializeArray();
		var options = {};
		for (var i = 0; i < tmp_options.length; i++) {
			options[ tmp_options[i].name ] = tmp_options[i].value;
		}
		$('#see-map').trigger('click');
		ymaps.ready(function() {
			$('#map-popup').shopsMap( options );
		});
	});
	$('#map-popup .pvz_filtrs input:checkbox').change(function(e) {
		$(this).closest('label')[ $(this).prop('checked') ? 'addClass' : 'removeClass' ]('active');
	});
	$('.delivery-list', wrapper).on('click', '.more-info', function(e) {
		e.preventDefault();
		$(this).closest('li').find('.more-info-holder').slideDown();
		$(this).removeClass('more-info');
		$(this).addClass('more-info-close');
		$(this).html('������ ����������');
	});
	$('.delivery-list', wrapper).on('click', '.more-info-close', function(e){
		e.preventDefault();
		$(this).closest('li').find('.more-info-holder').slideUp();
		$(this).removeClass('more-info-close');
		$(this).addClass('more-info');
		$(this).html('���������');
	});
}
initDeliveryPage();
function deleteAbsentProducts() {
    var productDeleteBtnsAr=$("#deleteAbsentProductBtn");

    $(productDeleteBtnsAr[0]).click();
    $(productDeleteBtnsAr[0]).parent().remove();
}