var autocomplete_city_cache = { byCity: {}, byZoneId: {} };

$.fn.redrawMySelect = function() {
}

ListingEasy.prototype.getGoods = function(options,callback,callbackFail){
	var me = this;

	if(typeof options !== "object" || !options.path ){
		return false;
	}
	options.get = options.get || {};
	options.get.fsort = options.get.fsort || 'new';
	options.get.page = options.get.page || 1;
	options.get.ajax = 3;
	$.get(options.path, options.get)
		.done(function(data){
			me.setUri(options.path, options.get);
			callback(data);
		}).fail(function(){
		callbackFail();
	});
}

ShoppingCart.prototype.deleteProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		var _this = this;
		shoppingCart.setQuantityNPrice( msg );

		if (typeof data.success == 'function') {
			data.success.call(_this, msg);
		}
		deleteProductEvent(msg);
	}
};

ShoppingCart.prototype.addProductCallback = function( data ) {
    var me = this;
    return function( msg, textStatus, jqXHR ) {
        me.productList = msg;
        this.setQuantityNPrice( msg );
        if (typeof data.success == 'function') {
            data.success.call(this, msg);
        }
        //addProductEvent( msg );
    }
};
ShoppingCart.prototype.setQuantityNPrice = function( msg ) {
    msg = msg || false;

    // �� �������� data
    if( msg ){
        var count = msg.count_contents;
        $('.j-header-basket .j-count,.js-count-in-basket').attr('data-css-count',count);
        cookieObj.setCookie('customerorderCnt',  msg.count_contents );

		var is_version_3_header = $('#header-v3').length;

		if(is_version_3_header) {
			setTotalCountProductsBasketModal(msg);
			setTotalCostBasketModal(msg);
			renderProductsBasketModal(msg);
		}
    }

	function renderProductsBasketModal(msg){
		var productsHtml = $.render[ 'basket_modal_products_list' ].render( msg.products, { currencies: window.PROSKTR.currencies } );

		$('.js-products-list-basket-modal').html('');
		$('.js-products-list-basket-modal').append(productsHtml);
	}

	function setTotalCostBasketModal(msg){
		$('.js-basket-modal-total').text(msg.total_price_formatted);
	}
	function setTotalCountProductsBasketModal(msg){
		var modalCount = $('.js-basket-modal-count'),
			defaultText = modalCount.attr('data-default-text');

		modalCount.attr('data-count',msg.count_contents);
		if(msg.count_contents === 0){
			modalCount.text(defaultText);
		}else{
			var text = msg.count_contents + ' ' + NumToRu_declination(msg.count_contents,'�����', '������', '�������') + ' � �������';

			modalCount.text(text);
		}
	}
	function NumToRu_declination(number,one,end_of_two_three_four,other){
		var number_string = number+'',
			last_numeric = number_string.slice(-1),
			is_11 = number_string.slice(-2) === '11';

		if(is_11){
			return end_of_two_three_four;
		}
		if(last_numeric === "1" ){
			return one;
		}
		if(last_numeric==="2"||last_numeric==="3"||last_numeric==="4"){
			return end_of_two_three_four;
		}
		return other;
	}
};
$.views.helpers({
    json_decode: function( val ) {
        return $.toJSON(val);
    },
});

var defaultValidateSetup = {
    description : {
        commonDescription : {
            required : '<div class="error">���� ����������� ��� ����������</div>',
            rus : '<div class="error">����� ������ ���� �� ������� �����</div>',
            name : '<div class="error">������ ���� �� ������� �����</div>',
            email : '<div class="error">�� ����� ����� e-mail</div>',
            phone_10 : '<div class="error">�� ����� ����� ����� ��������</div>',
            phone_11 : '<div class="error">�� ����� ����� ����� ��������</div>',
            pass : '<div class="error">������ ������ ��������� ������� 5 ��������</div>',
            reviewsText : '<div class="error">������ ������ ��������� ������� 15 ��������</div>',
            passConfirm : '<div class="error">���� "������" � "����������� ������:" ������ ���������</div>',
            valid : '<div class="success">��</div>',
            autocompleteCity : '<div class="error">�� ������ �����</div>',
            numeric : '<div class="error">�������� ������</div>',
            dateRu : '<div class="error">�������� ������ ���� (��.��.����)</div>',
        }
    },
    eachInvalidField: function( event, statuses, options ) {
        var dataDescription = $(this).data('description') || 'commonDescription';
        var text = '';
        $.each( statuses, function(i, val) {
            if ( val == false ) {
                if( i == 'conditional' ) {
                    text = options.description[ dataDescription ][ statuses.conditionalName ] || options.description[ 'commonDescription' ][ statuses.conditionalName ] || '';
                } else {
                    text = options.description[ dataDescription ][ i ] || options.description[ 'commonDescription' ][ i ] || '';
                }
            }
        } );
        fieldDescribedby = $(this).data('describedby');
        if(text) {
            $(this).addClass('valid-error');
            if (fieldDescribedby != undefined && $('#' + fieldDescribedby).length) {
                $('#' + fieldDescribedby).html(text);
            } else {
                $(this).closest('.js-row').find('.validation-description').html(text);
            }
        }
    },
    eachValidField : function() {
        fieldDescribedby = $(this).data('describedby');
        $(this).removeClass('valid-error');
        if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
            $('#'+fieldDescribedby).html('');
        } else {
            $(this).closest('.js-row').find('.validation-description').html('');
        }
    },
    invalid: function() {
        $(this).addClass('form-valid-error');
        var validation_obj = $(this).find('.validation-description:first');
        margin = validation_obj.offset();
        $("body").scrollTop( (+margin.top-50 || 0 ) );
    },
    valid: function() {
        $(this).removeClass('form-valid-error');
    },
    conditional: {
        rus: function( value, options ) {
			var success = value.length == 0 || /^[�-��-߸�\s\-,]+$/.test(value);
            return success;
        },
        name: function( value, options ) {
	        var success = value.length == 0 || /^[�-��-߸�a-zA-Z\s\-,]+$/.test(value);
	        return success;
        },
        email: function( value, options ) {
	        var success = value.length == 0 || /[-0-9a-z_]+@[-0-9a-z_^.]+\.[a-z]+/i.test(value);
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        phone_11: function( value, options ) {
            value = value.replace(/[^0-9]/g,"");
	        var success = value.length==PROSKTR.country.phoneLength || value.length==0;
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        pass: function( value, options ) {
	        var success = value.length>=5;
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        passConfirm: function( value, options ) {
            var passEl = $(this).closest('form').find('input[name=password]');
	        var success = value == passEl.val();
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        autocompleteCity: function( value, options ) {
            var holder = $('.city-tab.active', $(this).closest('.js-row'));
            var city = $('input[name=city]', holder);
            var zone_id = $('[name=zone_id]', holder);
	        var success = parseInt(zone_id.val()) > 0 && city.val().length > 0;
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        numeric: function( value, options ) {
            if ( !isNaN( value.trim() ) ) {
                if ( $(this).data('max') != undefined && value.trim() * 1 > $(this).data('max') * 1 ) {
	                console.log('fail');
					return false;
                }
                if ( $(this).data('min') != undefined && value.trim() * 1 < $(this).data('min') * 1 ) {
	                console.log('fail');
					return false;
                }
            }
	        var success =  !isNaN( value.trim() );
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        reviewsText: function( value, options ) {
	        var success =  value.length>=15;
	        if(!success){
		        console.log('fail');
	        }
	        return success;
        },
        ajaxEmail: function( value, options, a ) {
            // email_statuses:
            // 0 - not_checked / checking / error
            // 1 - checked - email_not_exists
            // 2 - checked - email_exists
            // 3 - logined
            // 4 - process
            var status = $(this).data('validEmailStatus');
            var prevEmail = $(this).data('prevEmail');
            if( status == undefined ) {
                status = 0;
            }
            if ( value != "" && prevEmail != value ) {
                status = 0;
                $(this).data('prevEmail', value);
            }
            if( status == 1 || status == 3 || customer.isLogin() ) {
                return true;
            }
            var emailEl = this;
            var passEl = $(this).closest('form').find('input[name=password]');
            var passRow = passEl.closest('.row');
            var pass_btn = $('.pass-btn', passRow);
            if( !pass_btn.data('login-binded') ) {
                pass_btn.data('login-binded', true);
                pass_btn.on('click', function() {
                    customer.login(
                        {
                            customerInfo: {
                                pass: passEl.val(),
                                email: emailEl.val(),
                            },
                            error: function(msg) {
                                emailEl.data('validEmailStatus', 0);
                            },
                            success: function(msg) {
                                emailEl.data('validEmailStatus', 3);
                            }
                        }
                    );
                });
            }
            customer.checkEmail({
                email: value,
                email_exists: function( msg ) {
                    passRow.removeClass('hidden');
                    $(emailEl).closest('.js-row').find('.validation-description').html(msg.err_mes);
                    $(emailEl).data('validEmailStatus', 2);
                },
                email_not_exists: function( msg ) {
                    passRow.addClass('hidden');
                    $(emailEl).data('validEmailStatus', 1);
                },
                error: function( msg ) {
                    passRow.addClass('hidden');
                    $(emailEl).closest('.js-row').find('.validation-description').html(msg.err_mes);
                    $(emailEl).data('validEmailStatus', 0);
                }
            });
            if( status == 1 || status == 3 || customer.isLogin() ) {
                return true;
            }
	        console.log('fail');
            return false;
        },
        ajaxPhone: function( value, options ) {
            // email_statuses:
            // 0 - not_checked / checking / error
            // 1 - checked - email_not_exists
            // 2 - checked - email_exists
            // 3 - logined
            // 4 - process
            var status = $(this).data('validPhoneStatus');
            var prevPhone = $(this).data('prevPhone');
            if( status == undefined ) {
                status = 0;
            }
            if ( prevPhone != value ) {
                status = 0;
                $(this).data('prevPhone', value);
            }
            if( status == 1 || status == 3 || customer.isLogin() ) {
                return true;
            }
            var phoneEl = this;
            var passEl = $(this).closest('form').find('input[name=password]');
            var passRow = passEl.closest('.row');
            var pass_btn = $('.pass-btn', passRow);
            if( !pass_btn.data('login-binded') ) {
                pass_btn.data('login-binded', true);
                pass_btn.on('click', function() {
                    customer.login(
                        {
                            customerInfo: {
                                pass: passEl.val(),
                                email: phoneEl.val(),
                            },
                            error: function(msg) {
                                phoneEl.data('validPhoneStatus', 0);
                            },
                            success: function(msg) {
                                phoneEl.data('validPhoneStatus', 3);
                            }
                        }
                    );
                });
            }
            customer.checkPhone({
                phone: value,
                phone_exists: function( msg ) {
                    passRow.removeClass('hidden');
                    $(phoneEl).closest('.js-row').find('.validation-description').html(msg.err_mes);
                    $(phoneEl).data('validPhoneStatus', 2);
                },
                phone_not_exists: function( msg ) {
                    passRow.addClass('hidden');
                    $(phoneEl).data('validPhoneStatus', 1);
                },
                error: function( msg ) {
                    passRow.addClass('hidden');
                    $(phoneEl).closest('.js-row').find('.validation-description').html(msg.err_mes);
                    $(phoneEl).data('validPhoneStatus', 0);
                }
            });
            if( status == 1 || status == 3 || customer.isLogin() ) {
                return true;
            }
	        console.log('fail');
            return false;
        },
        ajaxPhoneAndPassCode: function( value, options ) {
            var phoneEl = this;
			$(phoneEl).closest('form').find('.toOrder').removeClass('j-s-sbmt').addClass('disabled');
			if ( $(phoneEl).data('proccess') > 0 ) {
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if ( value.length > 0 ) {
				$(phoneEl).data('proccess', 1);
			}
			var status = $(this).data('validPhoneStatus');

			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( typeof lastVal == 'string' ) {
				lastVal = parseInt(lastVal.replace(/\D+/g,""));
			}
			var parsedVal = parseInt($(this).val().replace(/\D+/g,""));
			if( lastVal != parsedVal ) {
				$(this).data('lastVal', parsedVal);
				status = 0;
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if( status == 1 ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if( customer.isLogin() && customer.info.phone == parsedVal ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=passCode]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			$(phoneEl).removeClass('exists');

			customer.checkPhoneAndSendPassCode({
				phone: value,
				phone_exists: function( msg ) {
					if( msg.phoneThisLogin == 1 ) {
						$(phoneEl).data('validPhoneStatus', 1);
						$(phoneEl).trigger('change');
						$(phoneEl).data('proccess', 0);
						return true;
					}
					passRow.removeClass('d-none');
					$(phoneEl).closest('form').find('.to-order-btn').removeClass('j-s-sbmt').addClass('disabled');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab dotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login dotted">����� � ���� �������</a>';
					}
					$(phoneEl).addClass('exists').closest('.form-group').find('.validation-description').html('<div class="exists">' + msg.err_mes + '</div>');
					$(phoneEl).data('proccess', 0);
				},
				phone_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).data('validPhoneStatus', 1);
					$(phoneEl).trigger('change');
					$(phoneEl).data('proccess', 0);
					$(phoneEl).closest('form').find('.to-order-btn').addClass('j-s-sbmt').removeClass('disabled');
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
						$(phoneEl).closest('form').find('.to-order-btn').addClass('j-s-sbmt').removeClass('disabled');
					}
					$(phoneEl).closest('.form-group').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
					$(phoneEl).data('proccess', 0);
				}
			});
	        console.log('fail');
			return false;
        },
        dateRu: function( value, options ) {
            if( !/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(value) ) {
	            console.log('fail');
                return false;
            } else {
                var arrD = value.split(".");
                arrD[1] -= 1;
                var d = new Date(arrD[2], arrD[1], arrD[0]);
                if ((d.getFullYear() == arrD[2]) && (d.getMonth() == arrD[1]) && (d.getDate() == arrD[0])) {
                    return true;
                } else {
	                console.log('fail');
                    return false;
                }
            }
        },
        'autocomplete-city': function( value, option ) {
            if( $(this).is(':disabled') ) {
                return true;
            }
            if( value.length == 0 || value == 0 ) {
                $(this).closest('.for-autocomplete').parent().selectCity('showError', '�� ����� �����');
	            console.log('fail');
                return false;
            } else {
                return true;
            }
        }
    }
};
$.validateSetup(defaultValidateSetup);

var default_ajax_form_options = {
    dataType: 'json',
    timeout: 15000,
    beforeSubmit: function(formData, jqForm, options, form) {
        if( jqForm.data('zone_id_check') == 1 && array_search( formData, 'zone_id' ).value == 0 ) {
            return false;
        }
        if( jqForm.data('disabled') == 1 ) {
            return false;
        }
        jqForm.addClass('form-loading');
        formData.push({name: 'ajax', value: 3});
    },
    success: function(responseText, statusText, additParam, form) {
        form.removeClass('form-loading');
        if( responseText.data ) {
            $.each( responseText.data, function( selector, data ) {
                el = $( selector );
                el.html( data );
                if( $('form.validate', el) ) {
                    $('form.validate', el).validate();
                }
                $('.datepicker', el).datepicker();
                if( $('.default-validate-form', el).length ) {
                    $('.default-validate-form', el).validate();
                }
                initForm( el );
                var checkboxes = $('input:checkbox', el);
                if( checkboxes.length ) {
                    checkboxes.checkbox();
                }
                init_sc_autocomplete( el );
            });
        }
    }
};

$.widget("map.shopsMap", $.map.shopsMap, {
	options: {
		iconsPoint: {
			src: '/templates/flow/public/images/icon28.png',
			size: [33, 41],
			offset: [0, -41],
		},
		iconCluster: [
			{
				src: '/templates/flow/public/images/krug_ym.png',
				size: [40, 40],
				// ������, ����� ����� �������� �������� � ������� ��������.
				offset: [-20, -20]
			},
			{
				src: '/templates/flow/public/images/krug_ym.png',
				size: [50, 50],
				offset: [-25, -25]
			}
		],
		mapTabControl: '#map-control',
		mapLoading: '#mapLoading',
	}
});