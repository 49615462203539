document.addEventListener("DOMContentLoaded",function(){
    initBasketProductList();
    initOrdering();
    toggleDontCall();
    initSCSuggestion();
    initBonus();

    function initBonus(){
        $(document).on('change', 'select[name=customers_bonus]', function() {
            var div = $(this).closest('.js-customers-presents');
            // div.find('.ajax-success').hide();
            div.find('.js-ajax-loader').show();
            $.ajax({
                type: 'POST',
                data: {'choosen_bonus': $(this).val()},
                url: 'ajax_shopping_cart.php?action=set_bonus',
                dataType: 'json',
                context: this,
                success: function(msg) {
                    $($('.js-product-quantity')[0]).trigger('change');
                }
            });
        });
    }

    function initBasketProductList() {
        initChangeProductQuantity();
        initChangeProductWeightAndGrinding();
        initProductDelete();
        initCustomersBonus();
        initChangeSize();
    }

    function initOrdering(){
        initOrderingBadCode();
    }

    function initChangeSize(){
        $('#checkoutProductsList').on('change','.js-product-options-values',function(){
            var parent = $(this).closest('.js-product-item'),
                productId = parent.attr('data-product_id'),
                optionId = parent.attr('data-product_sub_id'),
                quantity = parent.find('.js-product-quantity').val(),
                selectedOptionId = $(this).val(),
                data = {
                    'products_id[]':productId+'{1}'+optionId,
                    'clicked': '��������',
                    'action': 'get_info',
                    'fromPage': 'shopping_cart',
                };

            data['id['+productId+'{1}'+optionId+'][1]'] = optionId;
            data['cart_size['+productId+'{1}'+optionId+']'] = productId+'{1}'+selectedOptionId;
            data['cart_quantity['+productId+'{1}'+optionId+']'] = quantity;

            $.post('ajax_shopping_cart.php?action=update_product_new',data,function(data) {
                rewriteTotalSum(data);
                rewriteProductsPrice(data);
                rewriteActions(data);
                rewriteProductsQuantity(data);
                redrawProducts(data);
            });
        });
    }
});

function initSCSuggestion() {
	if( window.PROSKTR.country.code != 'RU' ) {
		return ;
	}
	var 
		token = "f0018b902c3b14ca14ed104fc8fd52b1550234e7 ",
		type  = "ADDRESS",
		$street = $("#street-dadata");
		$house = $("#house-dadata");
		if ( !$street.length || !$house.length ) {
			return false;
		}
	if( $('#city-dadata').length == 0 ) {
		return;
	}

	$street.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "street",
		constraints: {
			locations: {
				city: $('#city-dadata').val(),
			},
		},
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

	$house.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "house",
		constraints: $street,
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

    var
        token = "f0018b902c3b14ca14ed104fc8fd52b1550234e7 ",
        type  = "PARTY",
        $organisation = $("#organisation-dadata");

    $organisation.suggestions({
        serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
        token: token,
        type: type,
        hint: false,
        onSelect: function(suggestion) {
            debugger;
            var wrapper = $('.j-legal-form');
            $('input[name="info_legal_psrn"]', wrapper).val( suggestion.data.ogrn ); // ����
            $('input[name="info_legal_itn"]', wrapper).val( suggestion.data.inn ); // ���
            $('input[name="info_legal_iec"]', wrapper).val( suggestion.data.kpp ); // ���
            $('input[name="info_legal_address"]', wrapper).val( suggestion.data.address.unrestricted_value ); // �����
            $('input[name="organisation"]', wrapper).val( suggestion.data.name.short_with_opf ); // ������� ��������
        },
    });

	setTimeout( function(){
		if ( $("#house-dadata").length && $("#street-dadata").val() != '' ) {
			$("#house-dadata").suggestions().fixData();
		}
	}, 1000);
};

function onSelectSuggestion(suggestion) {
	if ( suggestion.data.postal_code != null && $('#customerAddress input[name=index]').length )
	{
		$('#customerAddress input[name=index]').val( suggestion.data.postal_code ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=index]').val('');
	}
	if ( suggestion.data.house != null && $('#customerAddress input[name=house]').length )
	{
		$('#customerAddress input[name=house]').val( suggestion.data.house ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=house]').val('');
	}
	if ( suggestion.data.block != null && $('#customerAddress input[name=housing]').length ) {
		$('#customerAddress input[name=housing]').val( suggestion.data.block ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=housing]').val('').trigger('change');
	}
}

function initChangeProductQuantity(){
    $('.js-quantity-minus').click(function(){
        changeQuantity($(this),-1);
    });

    $('.js-quantity-plus').click(function(){
        changeQuantity($(this),1);
    });

    $('.js-product-quantity').change(function(){
        const productElement = $(this).closest('[data-bundles]');
        const productId = productElement.attr('data-product_id');
        const productSubId = productElement.attr('data-product_sub_id');
        const productFullId = productId+'{1}'+productSubId;
        const quantity = +$(this).val();
        const data = [
            {name: 'id['+productFullId+'][1]', value: productSubId},
            {name: 'products_id[]', value: productFullId},
            {name: 'cart_size['+productFullId+']', value: productFullId},
            {name: 'cart_quantity['+productFullId+']', value: quantity},
            {name: 'clicked', value: '��������'},
            {name: 'action', value: 'get_info'},
            {name: 'fromPage', value: 'shopping_cart'},
        ];

        $.post('ajax_shopping_cart.php?action=update_product_new',data)
            .done(function( data ){
                rewriteTotalSum(data);
                rewriteProductsPrice(data);
                rewriteActions(data);
                rewriteProductsQuantity(data);
                redrawProducts(data);
            });
    });

    function changeQuantity(buttonElement, changeTo){
        var input = $('.js-product-quantity',buttonElement.parent()),
            value = +input.attr('value'),
            max = +input.attr('max'),
            new_value = value;

        if(changeTo<0){
            if(value === 1){
                return false;
            }

            var buttonPlus = $('.js-quantity-plus',buttonElement.parent());

            buttonPlus.removeClass('hidden');
            --new_value;
            if(new_value === 1){
                buttonElement.addClass('hidden');
            }
        }else if(changeTo>0){
            if(value === max){
                return false;
            }

            var buttonMinus = $('.js-quantity-minus',buttonElement.parent());

            buttonMinus.removeClass('hidden');
            ++new_value;
            if(new_value === max){
                buttonElement.addClass('hidden');
            }
        }

        var inputDecorator = $('.js-number-value',buttonElement.parent());

        inputDecorator.html(new_value);
        input.attr('value',new_value).val(new_value).trigger('change');
    }
}

function initChangeProductWeightAndGrinding(){
    $('.js-product-option-weight-item').click(function(){
        var isAlreadyActive = $(this).hasClass('js-selected'),
            me = this;

        if(isAlreadyActive){
            return false;
        }

        switchButton();
        changeProductOption($(this).closest('[data-bundles]'));

        function switchButton() {
            $('.js-product-option-weight-item', $(me).closest('.js-product-option-weight')).removeClass('selected js-selected');
            $(me).addClass('selected js-selected');
        }
    });

    $('.js-product-grinding').change(function(event){
        changeProductOption($(this).closest('[data-bundles]'));
        event.stopImmediatePropagation();
    });

    function changeProductOption(productElement){
        setDisabledProductWeight(productElement);
        setDisabledProductGrinding(productElement);
        updateProductOptions(productElement);

        function setDisabledProductWeight(productElement){
            const bundles = productElement.data('bundles');
            const millArr = $('select.js-product-grinding',productElement).val().split('.');
            const mill = millArr[0];
            const millType = millArr[1];
            const disabledWeights = getSizeDisabledWeights(bundles, mill, millType);

            $('.js-product-option-weight-item', productElement).removeClass('none');

            for (var i = disabledWeights.length - 1; i >= 0; i--) {
                $('.js-product-option-weight-item[data-value="'+disabledWeights[i]+'"]', productElement).addClass('none');
            }
        }

        function setDisabledProductGrinding(productElement){
            const bundles = productElement.data('bundles');
            const weight = $('.js-product-option-weight-item.js-selected', productElement).data('value');
            const disabledMills = getSizeDisabledMillAndMillTypes(bundles, weight);

            $('select.js-product-grinding option', productElement).prop('disabled', false);

            for (var i = disabledMills.length - 1; i >= 0; i--) {
                $('select.js-product-grinding option[value="' + disabledMills[i].join('.') + '"]', productElement).prop('disabled', true);
            }
        }

        function updateProductOptions(productElement){
            const bundles = productElement.data('bundles');
            const millArr = $('select.js-product-grinding',productElement).val().split('.');
            const mill = millArr[0];
            const millType = millArr[1];
            const weight = $('.js-product-option-weight-item.js-selected', productElement).data('value');
            const selectedOption = bundles[mill][weight][millType];
            const productSubIdNew = selectedOption.id;
            const productSubIdOld = productElement.attr('data-product_sub_id');
            const productId = productElement.attr('data-product_id');
            const productQuantity = $('.js-product-quantity',productElement).attr('value');

            setNewSubId(productElement, productSubIdNew);

            deleteProduct().done(function(response){
                addProduct().done(renderProduct);
            });

            function setNewSubId(productElement, productSubIdNew){
                productElement.attr('data-product_sub_id',productSubIdNew);
            }

            function deleteProduct(){
                return $.post('/ajax_shopping_cart.php?action=update_product&ajax=1',{
                    products_id: [productId+'{1}'+productSubIdOld],
                    cart_delete: [productId+'{1}'+productSubIdOld],
                    fromPage: 'shopping_cart'
                })
            }
            function addProduct(){
                var data = {};

                data['id['+productId+'{1}'+productSubIdNew+'][1]'] = productSubIdNew;
                data['products_id[]'] = productId+'{1}'+productSubIdNew;
                data['cart_size['+productId+'{1}'+productSubIdNew+']'] = productId+'{1}'+productSubIdNew;
                data['cart_quantity['+productId+'{1}'+productSubIdNew+']'] = productQuantity;
                data.clicked = '��������';
                data.action = 'get_info'
                data.fromPage = 'shopping_cart'

                return $.post('ajax_shopping_cart.php?action=update_product_new',data);
            }

            function renderProduct(data){
                rewriteTotalSum(data);
                rewriteProductsPrice(data);
                rewriteActions(data);
                redrawProducts(data);
            }
        }
    }
}

function initProductDelete(){
    $('.js-product-delete').click(function(){
        const productElement = $(this).closest('[data-bundles]');
        const productId = productElement.attr('data-product_id');
        const productSubId = productElement.attr('data-product_sub_id');

        deleteFromServer().done(function(data){
            if(!data.products.length){
                $('#shopping-cart').hide();
                $('#shopping-cart-empty').show();
                return true;
            }
            productElement.remove();
            rewriteTotalSum(data);
            rewriteProductsPrice(data);
            rewriteActions(data);
            rewriteProductsQuantity(data);
            redrawProducts(data);
        })

        function deleteFromServer(){
            var data = {};

            data['products_id[]'] = productId + '{1}' + productSubId;
            data['cart_delete[]'] = productId + '{1}' + productSubId;
            data.fromPage = 'shopping_cart';

            return $.post('/ajax_shopping_cart.php?action=update_product&ajax=1', data);
        }
    });
}

function rewriteTotalSum(data){
    if(!data.real_total_text || !data.total_text || !data.real_total || !data.total){
        return false;
    }

    if(data.real_total !== data.total){
        $('.js-old-price').removeClass('none');
    }else{
        $('.js-old-price').addClass('none');
    }

    $('.js-current-price').html(data.total_text);
    $('.js-old-price').html(data.real_total_text);
}

function rewriteProductsPrice(data){
    if(typeof data === "undefined" || typeof data.products === "undefined"){
        return false;
    }
    for (var i=0, size = data.products.length; i < size; i++ ){
        const product = data.products[i];
        const final_price = parseInt(product.final_price);
        const real_price = parseInt(product.products_real_price);
        const productId = product.id.split('{')[0];
        const productSubId = product.id.split('}')[1];
        const productElement = $('.js-product-item[data-product_id="'+productId+'"][data-product_sub_id="'+productSubId+'"]');

        setPrice(productElement, real_price, final_price, product)
    }

    function setPrice(productElement, real_price, final_price, product){
        if ( real_price > final_price ){
            const percent = Math.round( ( real_price - final_price ) / real_price * 100 );

            $('.js-discount-percent-value',productElement).html(percent);
            $('.js-discount-percent',productElement).removeClass('none');
            $('.js-real-price',productElement).html(product.real_price_text).removeClass('none');
        }else{
            $('.js-discount-percent',productElement).addClass('none');
            $('.js-real-price',productElement).addClass('none');
        }
        $('.js-product-price',productElement).html(product.final_price_text);
    }
}

function rewriteActions(data){
    rewriteActionsList(data);
    rewriteActionMain(data);

    function rewriteActionsList(data) {
        if (typeof data.actions !== "undefined" && data.actions.length) {
            let container = $('.js-actions-sale');

            $(container).html('');
            $.each(data.actions, function (index, action) {
                if (action.value > 0 && action.pct > 0 && (action.cattovara != undefined || action.cattovara_madium != undefined || action.cattovara_main != undefined)) {
                    var str = '<span class="title"><strong>' + action.name + '</strong> <span class="moreMuch">(��� ' + action.value_text + ')</span></span>';
                    $(container).append(str);
                }
            });
        }
    }

    function rewriteActionMain(data) {
        let actionDiscountText = '';
        if (typeof data.totalDiscountHistory !== "undefined" && data.totalDiscountHistory.length) {
            $.each(data.totalDiscountHistory, function (index, history) {
                if (history.type == 'sale_action' && history.value > 0) {
                    actionDiscountText = '<p>��������� ������ ' + history.value + ' � �� ��������� ����� �' + history.action_name + "�</p>";
                }
            });
        }
        $('.js-main-action').html(actionDiscountText);
    }
}

function initCustomersBonus(){
    $('.js-customers_bonus').change(function(){
        if(!$(this).val()){
            return false;
        }

        $.post('ajax_shopping_cart.php?action=set_bonus',{
            choosen_bonus: $(this).val()
        }).done(function(){
            $.post('ajax_shopping_cart.php?action=get_info').done(function(data){
                rewriteTotalSum(data);
                rewriteProductsPrice(data);
                rewriteActions(data);
                redrawProducts(data);
            });
        })
    });
}

function rewriteProductsQuantity(data){
    if(!Array.isArray(data.products)){
        return false;
    }
    var products_quantity = 0;

    for(var i=0, size=data.products.length; i<size; i++){
        products_quantity += +data.products[i].quantity;
    }

    $('.js-count-in-basket').text(products_quantity);
    $('.js-count-in-basket-text').attr('data-css-count',products_quantity)
}

function redrawProducts(msg) {
    var productsHtml = '';

        productsHtml = $.render['shopping_cart_product'].render(
            msg.products,
            {customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
        );
    $('#checkoutProductsList').html(productsHtml);
    $('.j-order-products-count').text(msg.count_contents);
    let $otherHolder = $('.j-otherGroups');
    $otherHolder.html('');
    if ( msg.otherWhGroups && msg.otherWhGroups.length != 0 ) {
        $('.j-firstorder').removeClass('d-none');

        let i = 2;
        for ( group in msg.otherWhGroups ) {
            let headDiv = document.createElement('div');
            headDiv.className = 'ordergroup-head';
            headDiv.innerHTML = i + ' ����� - ������������ ��������';
            $otherHolder.append(headDiv);
            i++;

            let groupProductsHtml = $.render['shopping_cart_product'].render(
                msg.otherWhGroups[group],
                {customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
            );
            let entriesDiv = document.createElement('div');
            entriesDiv.className = 'products-list';
            entriesDiv.innerHTML = groupProductsHtml;
            $otherHolder.append(entriesDiv);

            let groupCount = 0;
            let groupPrice = 0;
            for (let index = 0; index < msg.otherWhGroups[group].length; index++) {
                const element = msg.otherWhGroups[group][index];
                groupCount += element.quantity * 1;
                groupPrice += element.quantity * element.final_price;
            }

            let totalsDiv = document.createElement('div');
            totalsDiv.className = 'summary';
            let totalsInner = '<div>����� � ���� ������ (<span class="group-count">' + (groupCount * 1) + '</span>��):</div><div class="group-price js-current-price">' + (groupPrice * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</div>';
            totalsDiv.innerHTML = totalsInner;
            $otherHolder.append(totalsDiv);
        };
    } else {
        $('.j-firstorder').addClass('d-none');
    }

    initChangeProductQuantity();
    initChangeProductWeightAndGrinding();
    initProductDelete();
    initCustomersBonus();
    $('select', '.product-item').each(function(){
        $(this).selectpicker();
    })
}

function initOrderingBadCode() {
    init_sc_autocomplete();

    customer.setCallback('onLogin', function() {
        $('#form-get-customer-city').trigger('submit');
        $('#accountInfo').hide();
    });

    var ajaxSCFormOptions = {
        dataType: 'json',
        error: function(context, xhr, e, status) {
        },
        uploadProgress: function(event, position, total, percent) {
        },
        beforeSubmit: function(formData, jqForm, options) {
            if( parse_str(options.url).action.indexOf('getCustomerCity') >= 0 ) {
                $('#form-get-login').hide();
            }
            // �������� ��������, ���� �����
            if( parse_str(options.url).action.indexOf('getShipping') >= 0 ) {
                if( formData.length ) {
                    if ( window.ga ) {
                        ga('ec:setAction','checkout', {
                            'step': 3,
                            'option': formData[0].value
                        });
                        ga('send', 'pageview');
                    }
                    if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
                    {
                        window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_city');
                    }
                }
                $('#accountInfo #acc-change-btn').hide();
                $('#customerAddress, #delivery').empty();
                $('#delivery').addClass('loading');
            }
    
            if( parse_str(options.url).action.indexOf('getAddressFields')>=0 ) {
                $('#customerAddress').empty();
                $('#customerAddress').addClass('loading');
            }
            if( $('#'+jqForm.attr('id')+'-loading').length ) {
                $('#'+jqForm.attr('id')+'-loading').css('visibility', 'visible');
            } else {
                $('.order .ajax-loading.default').css('visibility', 'visible');
            }
            if( $(jqForm).attr('id') == 'form-get-login' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
                if( $(jqForm).next().attr('id') == 'form-get-content' ) {
                    $(jqForm).hide().next().show();
                }
                $.each( $('#form-get-delivery').serializeArray(), function(i, val) {formData.push(val); } );
            }
            if( $(jqForm).attr('id') == 'form-get-content' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
                if( $(jqForm).prev().attr('id') == 'form-get-login' ) {
                    $(jqForm).hide().prev().show();
                }
            }
            formData.push({name: 'dataType', value: options.dataType});
        },
        success: function(responseText, statusText, xhr, $form) {
            if ( responseText.scrollTo == '#customerAddress' && responseText.resetCity ) {
                $('.skip').attr('action', $('.skip').attr('action') + '.getShipping.getAllContent' ).submit();
                return;
            }
            if( $form.attr('id') == 'createAccountForm' ) {
                $('[name=email_address]', $form).prop('readonly', true);
                $('[name=password]', $form).closest('.form-row').hide();
                if ( window.ga ) {
                    ga('ec:setAction','checkout', {
                        'step': 2,
                        'option': ''
                    });
                    ga('send', 'pageview');
                }
                if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
                {
                    window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_nameemailtel_filled');
                }
            }
            
            if( $('#'+$form.attr('id')+'-loading').length ) {
                $('#'+$form.attr('id')+'-loading').css('visibility', 'hidden');
            } else {
                $('.order .ajax-loading.default').css('visibility', 'hidden');
            }
            $.each( responseText.data, function( id, data ) {
                var place = $('#' + id);
                if( place.length ) {
                    place.html( data );
                    place.removeClass('loading');
                    // hidePaymentButtons(); // TODO
                    if( place.find('.default-validate-form').length ) {
                        place.find('.default-validate-form').validate();
                    }
                    if( place.find('.ajaxSCForm').length ) {
                        place.find('.ajaxSCForm').ajaxForm(ajaxSCFormOptions);
                    }
                    place.find('.tooltip').tooltip_init();
                    // if( place.find(':checkbox').length ) {
                    // 	place.find(':checkbox').checkbox();
                    // } // TODO
                    place.find('.phone-11-dig').phoneMask();
                    if( $.inArray('setInputFocus', responseText.options)!=-1 ) {
                        place.find('input:text:first').focus();
                    }
                    if( $('select.delivery-fitting', place).length ) {
                        $('select.delivery-fitting', place).trigger('change.fitting');
                    }
                    $('.with-placeholder input', place).each(function() {
                        $(this)[ $(this).val().trim().length == 0 ? 'removeClass' : 'addClass' ]('edited');
                    });
                    if( $('.fitting-descrption-popup-opened').length ) {
                        $(document).on('click', '.fitting-descrption-popup-opened', function(e) {
                            e.preventDefault();
                            $.magnificPopup.open({
                                items: {
                                    src: '#sc-primerka-popup',
                                    type: 'inline',
                                },
                            });
                        });
                        $('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
                            e.preventDefault();
                            $.magnificPopup.close();
                        });
                        $('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
                            e.preventDefault();
                            $.magnificPopup.close();
                            $('html, body').animate({ scrollTop: $('.cart-products').offset().top });
                        });
                    }
                }
                // BOF _gaq.push
                // EOF _gaq.push
            } );
            if( responseText.customerInfo != undefined ) {
                customer.logined = true;
                customer.info.email = responseText.customerInfo.email;
                customer.info.customer_id = parseInt(responseText.customerInfo.customers_id);
            }
            if( $.inArray('cityFocus', responseText.options)!=-1 ) {
                $('#customerCity .city-tab.active input[name=city]').focus();
            }
            if( $.inArray('delivery_date_for_time_change', responseText.options)!=-1 ) {
                $('select.delivery_date_for_time').delivery_date_for_time_change();
            }
            if( responseText.scrollTo && responseText.scrollTo != '#delivery' && responseText.scrollTo != '#customerCity' && responseText.scrollTo != '#customerAddress' ) {
                $('html, body').animate({ scrollTop: $(responseText.scrollTo).offset().top });
            } else if ( responseText.scrollTo && responseText.scrollTo == '#customerAddress' ) {
                $('html, body').animate({ scrollTop: $('#delivery').offset().top });
            }
            if ( responseText.shipping_cost != undefined ) {
                $('.j-cart-delivery-total').text( responseText.shipping_cost + ' ' + window.PROSKTR.currencies.rightMin );
                $('.j-cart-full-total').text( responseText.total_formatted + ' ' + window.PROSKTR.currencies.rightMin );
            }
            var stepsList = $('.order-steps');
            init_sc_autocomplete();
            initSCSuggestion();
            toggleDontCall();
        }
    }
    $('.ajaxSCForm').each(function() {
        $(this).ajaxForm(ajaxSCFormOptions);
    });

    $(document).on('click', '.j-s-sbmt', function(e) {
        e.preventDefault();
        if ( $(this).hasClass('disabled') ) {
            return;
        }
        $(this).closest('form').trigger('submit');
        if ( $(this).hasClass('toOrder') && !$(this).closest('form').hasClass('form-valid-error') ) {
            $('#cart').hide();
            $('#accountInfo').hide();
            $('#order-tab').show();
            $('#delivery').empty();
            $('#customerAddress').empty();
            $('html, body').animate({ scrollTop: $('#order-tab').offset().top });
        }
    });

    $(document).on('click', '.j-toOrder', function(e) {
        e.preventDefault();
        $(this).closest('.toOrderContainer').hide();
        $('#cart').hide();
        $('#accountInfo').show();
        $('#order-tab').show();
        $('#delivery').empty();
        $('#customerAddress').empty();
        $('html, body').animate({ scrollTop: $('#order-tab').offset().top });
        if ( $('#accountInfo .j-s-sbmt.toOrder').length ) {
            $('#accountInfo .j-s-sbmt.toOrder').trigger('click');
        }
    });

    $.fn.delivery_date_for_time_change = function() {
        this.each(function() {
            var delivery_row = $(this).closest('.delivery_selects');
            var time_select = delivery_row.find('[name='+$(this).attr('time')+']');
            var date_select = delivery_row.find('.delivery_date_for_time');
            var fitting_select = delivery_row.find('.delivery-fitting');
            time_select.empty();
            var arr = window[$(this).attr('time')];
            if (Array.isArray(arr)) {
                for (var i = 0; i < arr.length; i++) {
                    if( arr[i][0]==$(this).val() ) {
                        var o = new Option( arr[i][1], arr[i][1])
                        $(o).html( arr[i][1] );
                        time_select.append(o);
                    }
                }
            }
        });
    }
    $('#delivery').on('change', 'select.delivery_date_for_time', function( e ) {
        $(this).delivery_date_for_time_change();
    });
    $('select.delivery_date_for_time').delivery_date_for_time_change();
    $('#delivery').on('change', 'input[name=shipping]', function( e ) {
        var cureer_block = $(this).closest('.cureer-block');
        $('.delivery_selects').hide();
        $('.delivery_selects.select_' + $(this).val() ).show();
        cureer_block.addClass('active').siblings().removeClass('active');
        $('.change-delivery').removeClass('d-none');
        if( this.value=='pvz_pvz' ) {
            if( cureer_block.find('input[name=pvz_id]').val().length==0 ) {
                $('#delivery .shops_map_call').trigger('click');
                this.checked = false;
                return false;
            }
        }
        // $('.change-delivery').removeClass('d-none');
        // $('.more-delivery').addClass('d-none');
        $(this).closest('form').attr('action', 'shopping_cart.php?ajax=1&action=setShipping.getAddressFields.getPayments') // ������ �������, ������ ����� �� ��������:(
        $(this).closest('form').submit();
        if ( _isMobile() ) {
            $('.j-next-from-delivery').show();
        }
        $(this).closest('div.row').siblings(':visible').fadeOut({duration: 200});
        if ( window.ga ) {
            ga('ec:setAction','checkout', {
                'step': 4,
                'option': e.target.defaultValue
            });
            ga('send', 'pageview');
        }
        if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
        {
            window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_delivery');
        }
    });
    $('#delivery').on('click', '.j-next-from-delivery', function( e ) {
        if ( _isMobile() ) {
            $('#customerCity').hide();
            $('#delivery').hide();
            $('#customerAddress').show();
            $('html, body').animate({ scrollTop: $('#customerAddress').offset().top });
        }
    });
    $('#delivery').on( 'click', '.shops_map_call', function(e) {
        e.preventDefault();
        var options = $(this).data('map-options');
        $('#see-map').trigger('click');
        var el = $(this);
        ymaps.ready(function() {
            $('#map-popup').shopsMap($.extend(options, {
                pvzChoose: function( properties ) {
                    $('#pvz_id').val( properties.point_ident );
                    $('#pvz_resource').val( properties.delivery );
                    $('#pvz__city').val( properties.point_city );
                    $('#pvz_iml_date').val( properties.pvz_iml_date );
                    // el.text('�������� ����� ������ �� �����');
                    var cureer_block = el.closest('.cureer-block');
                    $('.send-value', cureer_block).text( properties.dates.send_format ); // ���� ��������
                    $('.delivery-value', cureer_block).text( properties.dates.delivery_from_format ); // ���� ��������
                    $('.col-2 .head', cureer_block).text( properties.dates.delivery_days_forprint ); // ��� ��������
    
                    $('.col-3 .head', cureer_block).text( properties.cost == 0 ? '���������' : properties.cost + '�' ).data('value', properties.cost); // ����
                    $('.col-3 .head-on-line', cureer_block).remove();
                    if( properties.free_cost == 1 && properties.cost > 0 ) {
                        $('.col-3', cureer_block).append( '<strong class="head-on-line tooltip" title="<b>���� ������ �������� ����� ���� ���������� ��� ������ ������!</b><br />��������� �������� ���������� ����� ������ ������.">��������� <div class="icon"><div class="point-pay_cache_no"></div></div></strong>' ); // ����
                        $('.col-3 .head-on-line', cureer_block).tooltip_init();
                    }
    
                    if( $.inArray(properties.delivery, options.typesToFitting) != -1 ) {
                        $('.fitting-holder', cureer_block).show();
                        // if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
                    } else {
                        $('.fitting-holder', cureer_block).hide();
                        // if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
                    }
    
                    $('.error', cureer_block).text('');
                    $('input[name=shipping]', cureer_block).prop('checked', true);
                    $('select.delivery-fitting', cureer_block).val(properties.fitting).trigger('change');
                    $('input[name=shipping]', cureer_block).trigger('change');
                    $('#pvz_text', cureer_block).html('<b>����� ����� ��������� �� ������:</b><br>' + properties.point_city + ', ' + properties.address + ' (���� ' + properties.delivery_name + ')');
                    $('#map-popup .js-close').trigger('click');
                }
            }));
        });
    } );
    $('#delivery').change(function(e) {
        if( e.target.name=='shipping' || $(this).find('input[name=shipping]:checked').length==0 ) {
        } else {
            $(this).attr('action', 'shopping_cart.php?ajax=1&action=setShipping');
            $(this).closest('form').submit();
        }
    });
    $(document).on('click', '.change-delivery', function(e){
        e.preventDefault();
        $('#delivery').find('.delivery-radio-list .cureer-block').removeClass('d-none');
        $('.change-delivery').addClass('d-none');
    });
    $('#delivery').on('click', '.del-all-product-label', function( e ) {
        e.preventDefault();
        data = {};
        var sendData = { products_id: [], cart_delete: [] };
        $('.del-product-label').each(function(){
            sendData.products_id.push( $(this).attr('products_id') );
            sendData.cart_delete.push( $(this).attr('products_id') );
        });
        var deleteProductCallback = typeof shoppingCart.options.deleteProductCallback == 'function' ? shoppingCart.options.deleteProductCallback( sendData ) : shoppingCart.deleteProductCallback( sendData ) ;
        $.ajax({
            url: '/ajax_shopping_cart.php?action=update_product&ajax=1',
            type: 'POST',
            data: sendData,
            dataType: 'json',
            success: deleteProductCallback
        });
    });
    $(document).on('change', '.payment-type', function(){
        var block = $(this).closest('.payment-block');
        var li = $(this).closest('li');
        block.siblings('.payment-block').find('input.payment-type').prop('checked', false);
        block.find('input[name=paymentGroup]').prop('checked', true).trigger('change');
        $('#payments').find('.select-pay-list li').not(li).addClass('d-none');
        $('.change-payment').removeClass('d-none');
        $('.more-payments').addClass('d-none');
        toggleDontCall();
        if ( $(this).closest('.other').length ) {
            $('.more-payments').addClass('no-hide');
        } else {
            $('.more-payments').removeClass('no-hide');
        }
    });
    
    $(document).on('click', '.change-payment', function(e){
        e.preventDefault();
        $('#payments').find('.select-pay-list li').removeClass('d-none');
        $('.more-payments').removeClass('d-none');
        $('.change-payment').addClass('d-none');
    });
    
    $(document).on('click', '.more-payments', function(e){
        e.preventDefault();
        if( $(this).hasClass('no-hide') ) {
            return;
        }
        if( $(this).hasClass('disabled') ) {
            $('#payments .other').slideUp();
            $(this).removeClass('disabled');
            $(this).text( $(this).data('text-hide-more') );
        } else {
            $('#payments .other').slideDown();
            $(this).addClass('disabled');		
            $(this).text( $(this).data('text-show-more') );
        }
    });

    $(document).on('change', 'input[name=dontCall]', function(){
        $('.dontCallLabel').data('current', $('input[name=dontCall]:checked').val());
    });

    $('#customerAddress, form#createAccountForm').on('change', 'input,select,textarea', function(e) {
        var dat = { 'dataType': 'json', 'forSave': {}};
        dat.form = $(this).closest('form').attr('id');
        dat['forSave'][$(this).attr('name')] = this.value;
        $.ajax({
            url: 'shopping_cart.php?action=setCustomerInfo&ajax=3',
            data: dat,
            dataType: 'json',
            type: 'POST',
            success: function(msg) {}
        });
    });

    $('form#createAccountForm').on('change', '[name=1click_order_submit]', function(){
        var btn = $(this).closest('form').find('.sc-submit');
        var altValue = btn.data('altValue');
        btn.data('altValue', btn.text());
        btn.text(altValue);
        if( this.checked ) {
            $('form#createAccountForm').ajaxFormUnbind();
        } else {
            $('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
        }
    });

    $('#createAccountForm input[name=passCode]').on('keyup', function(){
        var val = $(this).val();
        if ( val.length == 4 ) {
            customer.loginByPassCode({
                customerInfo: {
                    phone: $('#createAccountForm input[name=phone]').val(),
                    passCode: $('#createAccountForm input[name=passCode]').val(),
                    clearCart: 1,
                },
                error: function(msg) {
                    $('#createAccountForm input[name=phone]').data('validPhoneStatus', 0);
                    $('#createAccountForm input[name=passCode]')
                        .closest('.pass-row')
                        .find('.validation-description')
                        .html('<div class="error">' + msg.err_mes + '</div>')
                        .addClass('not-empty');
                },
                success: function(msg) {
                    $('#createAccountForm input[name=phone]').data('validPhoneStatus', 3);
                    if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
                        window.location.reload();
                    }
                }
            });
        }
    });
    
    $('#createAccountForm input[name=passCode]').on('change', function(){
        var val = $(this).val();
        if ( val.length > 0 && val.length != 4 ) {
            $('#createAccountForm input[name=passCode]')
                .closest('.pass-row')
                .find('.validation-description')
                .html('<div class="error">� ���������� ���� ������ ���� 4 �����</div>')
                .addClass('not-empty');
        }
    });
    
    $('#createAccountForm .sendNewPassCode').on('click', function(e){
        e.preventDefault();
        var $form = $('#createAccountForm');
        if ( $('input[name=phone]', $form).val() != '' ) {
            $.ajax({
                url: '/ajax_customer.php?action=resendNamePassCode&ajax=1',
                type: 'POST',
                data: $form.serializeArray(),
                dataType: 'json',
                context: this,
                success: function( msg ) {
                    $('.hint', $form).html(msg.err_mes);
                }
            });
        }
    });
}

function toggleDontCall() {
	var checkedPaymentEl = $('#payments .payment-block .select-pay-list input:radio:checked');
	var checkedPayment = $(checkedPaymentEl).val();
	var currentVal = $('.dontCallLabel').data('current');
	if ( checkedPaymentEl.length && checkedPayment != 'cod' ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else if ( checkedPaymentEl.length && checkedPayment == 'cod' ) {
		$('.dontCallLabel').css('display', 'none');
		$('input[name=dontCall][value=off]').prop('checked', true);
	} else if ( $('input[name=dontCall]').data('showdontcall') == 1 ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else {
		$('.dontCallLabel').css('display', 'none');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	}
}